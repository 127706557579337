import api from 'api';
import _, { isNull, isUndefined } from 'lodash';
import { useDispatch } from 'react-redux';
import { message } from 'antd';
import base64toPDF from 'lib/base64ToPDF';


let typesRaw = {
	SET_STATE: 'SET_STATE',
	RESET_ALL: 'RESET_ALL',
	RESET_ACTIVE_ADDRESS: 'RESET_ACTIVE_ADDRESS',
	RESET_ACTIVE_CONTACT: 'RESET_ACTIVE_CONTACT',
	RESET_CONTACT_FORM_VALUES: 'RESET_CONTACT_FORM_VALUES',
	RESET_ADDRESS_FORM_VALUES: 'RESET_ADDRESS_FORM_VALUES',
	SET_ACTIVE_ADDRESS: 'SET_ACTIVE_ADDRESS',
	SET_ADDRESS_FORM_VALUES: 'SET_ADDRESS_FORM_VALUES',
	SET_ACTIVE_CONTACT: 'SET_ACTIVE_CONTACT',
	SET_CONTACT_FORM_VALUES: 'SET_CONTACT_FORM_VALUES',
	//Customer Entry
	SET_HEADER_VALUE: 'SET_HEADER_VALUE',
	SET_CONTACT: 'SET_CONTACT',
	EDIT_CONTACT: 'EDIT_CONTACT',
	SET_LINE_VALUE: 'SET_LINE_VALUE',
	ADD_BILLING_ADDRESS: 'ADD_BILLING_ADDRESS',
	ADD_SHIPPING_ADDRESS: 'ADD_SHIPPING_ADDRESS',
	EDIT_BILLING_ADDRESS: 'EDIT_BILLING_ADDRESS',
	EDIT_SHIPPING_ADDRESS: 'EDIT_SHIPPING_ADDRESS',
	SET_DEFAULT_CONTACT: 'SET_DEFAULT_CONTACT',
	SET_DEFAULT_ADDRESS: 'SET_DEFAULT_ADDRESS',
};


export const types = Object.keys(typesRaw).reduce((all, key) => ({
	...all,
	[ key ]: `BP_MANAGEMENT/${typesRaw[ key ]}`,
}), {});


const activeAddress = {
		bp_id: '',
		company_name: '',
	},

	addressForm = {
		row_index: 0,
        type: '',
        address_line_1: '',
        address_line_2: '',
        address_city: '',
        address_state: '',
        address_zip: '',
        address_name: '',
        address_attn: '',
        address_country: '',
	},

	activeContact = {
		contact_id: '',
		bp_id: '',
		company_name: '',
		is_default: '',
	},

	contactForm = {
		row_index: 0,
		first_name: '',
		last_name: '',
		primary_phone: '',
		email: '',
		position: '',
		notes: '',
		contact_name: '',
		is_default: '',
			orderConfirmation: '',
			invoices: '',
			portalInvite: '',
			marketingEmails: '',
	},

	defaultState = {
		loading: false,
		saving: false,

		//Searches and Filters
		bpListPageNumber: 0,
		bpListPageSize: 20,
		bpListTotalRows: 0,
		bpListSearchString: '',
		bpListSearchStatus: '',
		bpListSearchTerms: '',
		bpListSearchSales_person: '',
		bpListSearchType: '',

		bp_id: '',

		bpHeader: {
			bp_id: '',
			bp_name: '',
			bp_type: '',
			sales_person: '',
			sales_employee_id: '',
			customer_payment_terms: '50 Down / Auto',
			primary_phone: '',
			website: '',
			notes: '',
			foreign_name: '',
			default_warehouse: null,
		},
		warehouseOptions: [
			/*
				FldValue
				IndexID
			*/],

		default: {
			bill_address_line_1: '',
			bill_address_line_2: '',
			bill_address_city: '',
			bill_address_state: '',
			bill_address_zip: '',
			bill_address_name: '',
			bill_address_attn: '',
			bill_address_country: '',
			
			ship_address_line_1: '',
			ship_address_line_2: '',
			ship_address_city: '',
			ship_address_state: '',
			ship_address_zip: '',
			ship_address_name: '',
			ship_address_attn: '',
			ship_address_country: '',

			contact_first_name: '',
			contact_last_name: '',
			contact_name: '',
		},

		bpShippingAddress: [{...addressForm}],
		bpBillingAddress: [{...addressForm}],

		bpList: [],
		bpAddress: [],
		countries: [],
		states: [],
		statesByCountry: {},
		addressLoading: false,
		addressSaving: false,
		showAddressForm: false,
		isNewAddress: false,
		activeAddress: { ...activeAddress },
		bpAddressSearchString: '',
		bpCitySearchString: '',
		bpStateSearchString: '',
		bpAddressSearchType: 'address',

		contacts: [{...contactForm}],
		bpContacts: [],
		contactLoading: false,
		contactSaving: false,
		showContactForm: false,
		isNewContact: false,
		contactForm: { ...contactForm },
		activeContact: { ...activeContact },
		bpContactSearchString: '',

		sales_persons: [],
		salesPersonsList: [],
		terms: [],

		addContactVisible: false,
		contactCopy: false,
		contactEdit: false,

		addAddressVisible: false,
		addressCopy: false,
		addressEdit: false,
		addressType: '',
		saveSuccess: false,
		currentBPName: '', //used to compare if bp name was updated

	};


export const reducers = (state = defaultState, action) => {
	switch(action.type) {
		case types.SET_STATE:
			return { ...state, ...action.data };


		case types.RESET_ALL:
			return { ...defaultState };


		case types.RESET_ACTIVE_ADDRESS:
			return {
				...state,
				activeAddress: {
					...activeAddress,
				},
			};

		case types.RESET_ADDRESS_FORM_VALUES:
			return {
				...state,
				addressForm: {
					...addressForm,
				},
			};

		case types.RESET_ACTIVE_CONTACT:
			return {
				...state,
				activeContact: {
					...activeContact,
				},
			};

		case types.RESET_CONTACT_FORM_VALUES:
			return {
				...state,
				contactForm: {
					...contactForm,
				},
			};


		case types.SET_ACTIVE_ADDRESS:
			return {
				...state,
				activeAddress: {
					...state.activeAddress,
					...action.address,
				},
			};


		case types.SET_ADDRESS_FORM_VALUES:
			return {
				...state,
				addressForm: {
					...state.addressForm,
					...action.address,
				},
			};


		case types.SET_ACTIVE_CONTACT:
			return {
				...state,
				activeContact: {
					...state.activeContact,
					...action.contact,
				},
			};


		case types.SET_CONTACT_FORM_VALUES:
			return {
				...state,
				contactForm: {
					...state.contactForm,
					...action.contact,
				},
			};

		case types.SET_HEADER_VALUE:
			{
				return {
					...state,
					bpHeader: {
						...state.bpHeader,
						...action.data,
					},
				};
			}

		case types.ADD_LINE:
			{
				return {
					...state,
					contacts: [
						...state.contacts,
						{
							...contactForm,
							row_index: state.contacts.length,
						},
					],
				};
			}

		case types.SET_LINE_VALUE:
			{
				return {
					...state,
					contacts: [
						...state.contacts.slice(0, action.index),
						{
							...state.contacts[action.index],
							[action.property]: action.value,
						},
						...state.contacts.slice(action.index + 1),
					],
				};
			}

			case types.SET_CONTACT:
				{
					
					return {
						...state,
						contacts: [
							...state.contacts,
							{
								...action.data,
								row_index: state.contacts.length,
							},
						],
					};
				}

				case types.EDIT_CONTACT: {
					const updatedContact = state.contacts.map((contact, index) => {
						if (index === action.index) {
							// If the current index matches the one passed in the action, update the data
							return {
								...contact,
								...action.data,
							};
						}
						// Otherwise, return the original address
						return contact;
					});
				
					return {
						...state,
						contacts: updatedContact,
					};
				}

			case types.ADD_BILLING_ADDRESS:
				{
					return {
						...state,
						bpBillingAddress: [
							...state.bpBillingAddress,
							{
								...action.data,
								row_index: state.bpBillingAddress.length,
							},
						],
					};
				}

			case types.ADD_SHIPPING_ADDRESS:
				{
					return {
						...state,
						bpShippingAddress: [
							...state.bpShippingAddress,
							{
								...action.data,
								row_index: state.bpShippingAddress.length,
							},
						],
					};
				}

			case types.EDIT_BILLING_ADDRESS: {
				const updatedBillingAddress = state.bpBillingAddress.map((address, index) => {
					if (index === action.index) {
						// If the current index matches the one passed in the action, update the data
						return {
							...address,
							...action.data,
						};
					}
					// Otherwise, return the original address
					return address;
				});
			
				return {
					...state,
					bpBillingAddress: updatedBillingAddress,
				};
			}

			case types.EDIT_SHIPPING_ADDRESS: {
				const updatedShippingAddress = state.bpShippingAddress.map((address, index) => {
					if (index === action.index) {
						// If the current index matches the one passed in the action, update the data
						return {
							...address,
							...action.data,
						};
					}
					// Otherwise, return the original address
					return address;
				});
			
				return {
					...state,
					bpShippingAddress: updatedShippingAddress,
				};
			}

			case types.SET_DEFAULT_CONTACT: {
				const { rowIndex, record } = action;
				return {
					...state,
					default: {
						...state.default,
						contact_first_name: record.first_name || '',
						contact_last_name:  record.last_name || '',
						contact_name: record.contact_name || `${record.first_name} ${record.last_name}`,
					},
					contacts: state.contacts.map((contact, index) => ({
						...contact,
						is_default: index === rowIndex ? 'Y' : '',
					})),
				}
			}

			case types.SET_DEFAULT_ADDRESS: {
				const { data, rowIndex, record } = action;
				if (data === 'billing'){
					return {
						...state,
						default: {
							...state.default,
							bill_address_line_1: record.address_line_1 || '',
							bill_address_line_2: record.address_line_2 || '',
							bill_address_city: record.address_city || '',
							bill_address_state: record.address_state || '',
							bill_address_zip: record.address_zip || '',
							bill_address_name: record.address_name || '',
							bill_address_attn: record.address_attn || '',
							bill_address_country: record.address_country || '',
						},
						bpBillingAddress: state.bpBillingAddress.map((address, index) => ({
							...address,
							is_default: index === rowIndex ? 'Y' : '',
						})),
					};
				} else {
					return {
						...state,
						default: {
							...state.default,
							ship_address_line_1: record.address_line_1 || '',
							ship_address_line_2: record.address_line_2 || '',
							ship_address_city: record.address_city || '',
							ship_address_state: record.address_state || '',
							ship_address_zip: record.address_zip || '',
							ship_address_name: record.address_name || '',
							ship_address_attn: record.address_attn || '',
							ship_address_country: record.address_country || '',
						},
						bpShippingAddress: state.bpShippingAddress.map((address, index) => ({
							...address,
							is_default: index === rowIndex ? 'Y' : '',
						})),
					};
				}
			}

		default:
			return state;

	}
};


export const actions = {
	setState: (data) => ({ type: types.SET_STATE, data }),

	resetState: () => ({ type: types.RESET_ALL }),

	resetActiveAddress: () => ({ type: types.RESET_ACTIVE_ADDRESS }),

	resetAddressFormValues: () => ({ type: types.RESET_ADDRESS_FORM_VALUES }),

	resetActiveContact: () => ({ type: types.RESET_ACTIVE_CONTACT }),

	resetContactFormValues: () => ({ type: types.RESET_CONTACT_FORM_VALUES }),

	set_header_value: (data) => ({ type: types.SET_HEADER_VALUE, data }),

	set_contact: (data) => ({ type: types.SET_CONTACT, data }),

	add_contact: (data, mode) =>  async (dispatch, getState) => {
		const isValid = checkRequiredInputs(data, 'contact');
		if(mode === 'edit') {
			if (isValid) {
				dispatch(actions.edit_contact(data.row_index, data));
				dispatch(actions.setState({
					addContactVisible: false,
					contactCopy: false,
					contactEdit: false,
				}));
			} else {
				message.error('Fields marked with a * are required');
				return;
			}
		} else {
			if (isValid) {
				dispatch(actions.set_contact(data));
				dispatch(actions.setState({
					addContactVisible: false,
					contactCopy: false,
					contactEdit: false,
				}));
			} else {
				message.error('Fields marked with a * are required');
				return;
			}
		}
	},

	edit_contact: (index, data) => ({ type: types.EDIT_CONTACT, index, data }),

	set_default_contact: (rowIndex, record) => ({ type: types.SET_DEFAULT_CONTACT, rowIndex, record}),

	set_default_address: (rowIndex, data, record) => ({ type: types.SET_DEFAULT_ADDRESS, rowIndex, data, record}),

	add_billing_address: (data) => ({ type: types.ADD_BILLING_ADDRESS, data }),

	add_shipping_address: (data) => ({ type: types.ADD_SHIPPING_ADDRESS, data }),

	edit_billing_address: (index, data) => ({ type: types.EDIT_BILLING_ADDRESS, index, data }),

	edit_shipping_address: (index, data) => ({ type: types.EDIT_SHIPPING_ADDRESS, index, data }),

	set_line_value: (index, property, value) => ({ type: types.SET_LINE_VALUE, index, property, value }),

	getBPList: (params = {}) => async (dispatch, getState) => {
		const state       = getState().bp,
			searchValue = state.bpListSearchString;

		dispatch(actions.setState({ loading: true }));
		dispatch(actions.load_required_data());
		try {
			let results = await api.get('/bp/list', {
				params: {
					search: searchValue,
					...params,
				},
			});

			dispatch(actions.setState({ bpList: results.bps }));
		} catch(err) {
			console.log(`Error getting BP list: ${err}`);
		}

		dispatch(actions.setState({ loading: false }));
	},

	getAllBPList: (params = {}) => async (dispatch, getState) => {
		const state       = getState().bp,
			page        = state.bpListPageNumber,
			limit       = state.bpListPageSize,
			searchValue = state.bpListSearchString,
			bpListSearchStatus = state.bpListSearchStatus,
			bpListSearchTerms = state.bpListSearchTerms,
			bpListSearchSales_person = state.bpListSearchSales_person,
			bpListSearchType = state.bpListSearchType;

		dispatch(actions.setState({ loading: true }));
		dispatch(actions.load_required_data());
		try {
			let results = await api.get('/bp/search', {
				params: {
					page: page,
					limit: limit,
					search: searchValue,
					status: bpListSearchStatus,
					terms: bpListSearchTerms,
					sales_person_name: bpListSearchSales_person,
					type: bpListSearchType,
					...params,
				},
			});

			dispatch(actions.setState({ bpList: results.bps }));
			dispatch(actions.setState({ bpListTotalRows: results.total_rows }));
			dispatch(actions.setState({ terms: results.terms }));
		} catch(err) {
			console.log(`Error getting BP list: ${err}`);
		}

		dispatch(actions.setState({ loading: false }));
	},

	loadBPEntry: (cardCode) => async (dispatch, getState) => {
		console.log('[BPManagement.redux] loadBPEntry');
		dispatch(actions.setState({loading: true}));
		const state       = getState().bp;
			try {
				let bpHeader = await api.get('/bp/bp', {
					params: {
						bp_id: cardCode,
					},
				}),
				contacts_response = await api.get('/bp/contact', {
					params: {
						cardCode,
						searchString: '',
					},
				}),
				billing_address_response = await api.get('/bp/address', {
					params: {
						cardCode,
						searchStreetBlock: '',
						searchCityZip: '',
						searchState: '',
						type: 'B',
						managementPage: true,
					},
				}),
				shipping_address_response = await api.get('/bp/address', {
					params: {
						cardCode,
						searchStreetBlock: '',
						searchCityZip: '',
						searchState: '',
						type: 'S',
						managementPage: true,
					},
				}),
				warehouse_options = await api.get('/warehouse/get-warehouse-options-bp/', {
					params: {},
				});
				// Extract the array from the response
				const billing_address = billing_address_response.addresses || [];
				const shipping_address = shipping_address_response.addresses || [];
				const default_warehouses = warehouse_options.options || [];
				const contacts = contacts_response.contacts || [];
				// Add row_index property to billing addresses
				const bpBillingAddress = billing_address.map((address, index) => ({
					...address,
					row_index: index,
				}));

				// Add row_index property to shipping addresses
				const bpShippingAddress = shipping_address.map((address, index) => ({
					...address,
					row_index: index,
				}));

				// Add row_index and first and last name property to contact
				const updatedContact = contacts.map((contact, index) => ({
					...contact,
					row_index: index,
				}));

				let defaultShipAddress = getDefault(bpShippingAddress);
				let defaultBillAddress = getDefault(bpBillingAddress);
				let defaultContact     = getDefault(updatedContact);

				//console.log('bpHeader.bpHeader');
				//console.log(bpHeader.bpHeader[0]);

				dispatch(actions.setState({ currentBPName: bpHeader.bpHeader[0].bp_name}));
				dispatch(actions.setState({ bpHeader: { ...bpHeader.bpHeader[0] } }));
				dispatch(actions.setState({ contacts: updatedContact }));
				dispatch(actions.setState({ bpBillingAddress: bpBillingAddress }));
				dispatch(actions.setState({ warehouseOptions: default_warehouses }));
				dispatch(actions.setState({ bpShippingAddress: bpShippingAddress }));
				dispatch(actions.setState({ default: {
					...state.default,
					bill_address_line_1: defaultBillAddress[0].address_line_1 || '',
					bill_address_line_2: defaultBillAddress[0].address_line_2 || '',
					bill_address_city: defaultBillAddress[0].address_city || '',
					bill_address_state: defaultBillAddress[0].address_state || '',
					bill_address_zip: defaultBillAddress[0].address_zip || '',
					bill_address_name: defaultBillAddress[0].address_name || '',
					bill_address_attn: defaultBillAddress[0].address_attn || '',
					bill_address_country: defaultBillAddress[0].address_country || 'US',
					
					ship_address_line_1: defaultShipAddress[0].address_line_1 || '',
					ship_address_line_2: defaultShipAddress[0].address_line_2  || '',
					ship_address_city: defaultShipAddress[0].address_city || '',
					ship_address_state: defaultShipAddress[0].address_state || '',
					ship_address_zip: defaultShipAddress[0].address_zip || '',
					ship_address_name: defaultShipAddress[0].address_name  || '',
					ship_address_attn: defaultShipAddress[0].address_attn || '',
					ship_address_country: defaultShipAddress[0].address_country || 'US',
		
					contact_first_name: defaultContact[0].first_name || '',
					contact_last_name: defaultContact[0].last_name || '',
					contact_name: defaultContact[0].contact_name || '',
				}}))
				dispatch(actions.setState({loading: false}))

				console.log('bpShippingAddress from store after load', bpShippingAddress);
				console.log('bpBillingAddress from store after load', bpBillingAddress);
				console.log('defaultShipAddress from store after load', defaultShipAddress);
				console.log('defaultBillAddress from store after load', defaultBillAddress);
			}catch(err) {
				console.log(err);
				dispatch(actions.setState({loading: false}))
			}
	},

	bpListSetSearchString: (searchString) => (dispatch) => {
		dispatch(actions.setState({ bpListSearchString: searchString }));
		dispatch(actions.getBPList());
	},

	bpAddressSetSearchString: (cardCode, type, searchString) => (dispatch) => {
		switch (type) {
			case 'address':
				dispatch(actions.setState({ bpAddressSearchString: searchString }));
				break;

			case 'city':
				dispatch(actions.setState({ bpCitySearchString: searchString }));
				break;

			case 'state':
				dispatch(actions.setState({ bpStateSearchString: searchString }));
				break;
			default:
				return false;
		}

		dispatch(actions.loadBPAddressList(cardCode));
	},

	bpContactSetSearchString: (cardCode, searchString) => (dispatch) => {

		dispatch(actions.setState({ bpContactSearchString: searchString }));
		dispatch(actions.loadBPContactList(cardCode));
	},

	loadBPDetailView: (bp_id) => async (dispatch, getState) => {
		dispatch(actions.setState({ loading: true }));

		try {
			let results = await api.get('/bp', {
				params: {
					bp_id,
				},
			});


			dispatch(actions.setState({ bpHeader: { ...results.bpHeader[0] } }));
			dispatch(actions.loadBPAddressList(bp_id));
			dispatch(actions.loadBPContactList(bp_id));
		} catch(err) {
			// do nothing
		}

		dispatch(actions.setState({ loading: false }));
	},

	loadBPAddressList: (cardCode) => async (dispatch, getState) => {
		const state             = getState().bp,
			searchStreetBlock = state.bpAddressSearchString,
			searchCityZip     = state.bpCitySearchString,
			searchState       = state.bpStateSearchString;

		dispatch(actions.setState({ addressLoading: true }));

		try {
			let results = await api.get('/bp/address', {
				params: {
					cardCode,
					searchStreetBlock,
					searchCityZip,
					searchState,
				},
			});

			dispatch(actions.setState({ bpAddress: results.addresses }));
		} catch(err) {
			console.log(`Error getting BP Address List: ${err}`);
		}
		dispatch(actions.setState({ addressLoading: false }));
	},

	loadBPContactList: (cardCode) => async (dispatch, getState) => {
		console.log('[BPManagement.redux] loadBPContactList');
		const state = getState().bp,
			searchString = state.bpContactSearchString;

		dispatch(actions.setState({ contactLoading: true }));

		console.assert(searchString, '[BPManagement.redux] searchString is null');
		try {
			let results = await api.get('/bp/contact', {
				params: {
					cardCode,
					searchString, // BUG how do we know this isnt empty?
				},
			});

			dispatch(actions.setState({ bpContacts: results.contacts }));
		} catch(err) {
			console.log(`Error getting BP Contact List: ${err}`);
		}
		dispatch(actions.setState({ contactLoading: false }));
	},

	getStatesAndCountries: () => async (dispatch, getState) => {
		dispatch(actions.setState({ addressLoading: true }));

		try {
			let results = await api.get('/bp/locations');

			dispatch(actions.setState({ countries: results.countries }));
			dispatch(actions.setState({ statesByCountry: results.states }));
		} catch(err) {
			console.log(`Error getting Country and State lists: ${err}`);
		}

		dispatch(actions.setState({ addressLoading: false }));
	},

	setActiveAddress: (address) => ({ type: types.SET_ACTIVE_ADDRESS, address }),

	setAddressValues: (address) => ({ type: types.SET_ADDRESS_FORM_VALUES, address }),

	saveBP: () => async (dispatch, getState) => {
		console.log('[BPManagement.redux] saveBP');
		const state 		= getState().bp,
		header				= state.bpHeader,
		contacts    		= state.contacts,
		bpShippingAddress 	= state.bpShippingAddress,
		bpBillingAddress 	= state.bpBillingAddress,
		defaults			= state.default,
		currentBPName		= state.currentBPName;
		let result_bp_id;

		let checkedContacts    = checkRequiredDefault(contacts);
		let checkedShipAddress = checkRequiredDefault(bpShippingAddress);
		let checkedBillAddress = checkRequiredDefault(bpBillingAddress);
		let checkHeader		   = checkRequiredInputs(header, 'header');


		//validates requirements before saving
		if( !checkedContacts || !checkedShipAddress || !checkedBillAddress ){
			message.error('Must have a least one contact, shipping address and billing address.');
			return;
		}
		if(!checkHeader){
			message.error('Header fields marked with * must not be blank');
			return;
		}

		dispatch(actions.setState({saving: true}));

		if(currentBPName !== header.bp_name){
			try {
				
				let names = await api.get('/bp/business_name', {
						params: {
							name: header.bp_name,
						},
				});
	
				if(names.results.recordset.length >= 1){
					message.error('This business name already exist.');
					return false;
				} else {
					console.log('BP Name passed');
				}
	
			} catch (err) {
				console.error('Address Names Must be Unique ', err);
			}
		}

		const {
			shipping_address_city,
			shipping_address_state,
			shipping_address_street,
			shipping_address_zip,
			default_billing_address,
			default_shipping_address,
			billing_address_city,
			billing_address_state,
			billing_address_street,
			billing_address_zip,
			contact_name,
			...updatedBpHeader
		} = header;

		//console.log(updatedBpHeader);
		//console.log(header);

		try {

			let headerPayload = {...updatedBpHeader, defaults};
			console.log('headerPayload ', headerPayload);
			console.log('updatedBpHeader ', updatedBpHeader);

			let result = await api.put('/bp', { bp: headerPayload, managementPage: true });
			headerPayload = {
				...updatedBpHeader,
				bp_id: result.bp_id,
				defaults: defaults
			};
			console.log('headerPayload  AFTER write', headerPayload);
			console.log('updatedBpHeader AFTER write', updatedBpHeader);
			const contactPromises = contacts
				.map(async (contact) => {
					await api.put('/bp/contact', {
						contact: {
							...contact,
							bp_id: result.bp_id,
						},
					});
				});
			const billAddressPromises = bpBillingAddress
				.map(async (address) => {
					await api.put('/bp/address', {
						address: {
							...address,
							bp_id: result.bp_id,
						},
					});
				});
			const shipAddressPromises = bpShippingAddress
				.map(async (address) => {
					await api.put('/bp/address', {
						address: {
							...address,
							bp_id: result.bp_id,
						},
					});
				});

		try {
			await Promise.all([...contactPromises, ...shipAddressPromises, ...billAddressPromises])
			//.then(() => api.put('/bp', { bp: headerPayload, managementPage: true })); //update default after creating new records
			dispatch(actions.loadBPEntry(result.bp_id));
			dispatch(actions.setState({bp_id: result.bp_id}));
			message.info(`Customer ${result.bp_id} successfully saved`);
		} catch(err) {
			console.log('Error writting Address or Contacts', err)
		}
			dispatch(actions.setState({
				saving: false,
				saveSuccess: true
			}));

			result_bp_id = result.bp_id;
			
		} catch(err) {
			console.log('Error saving BP ', err);
			dispatch(actions.setState({
				saving: false,
				saveSuccess: false
			}));
		}
	},

	saveAddress: () => async (dispatch, getState) => {
		const state = getState().bp,
			addressForm = state.addressForm,
			activeAddress = state.activeAddress;

		const address = {
			bp_id: activeAddress.bp_id,
			type: addressForm.type,
			name: addressForm.name,
			line1: addressForm.line1,
			line2: addressForm.line2,
			city: addressForm.city,
			state: addressForm.state,
			zip: addressForm.zip,
			country: addressForm.country,
		};

		dispatch(actions.setState({ addressSaving: true }));


		try {
			await api.put('/bp/address', {
				address: {
					...address,
				},
			});
		} catch(err) {
			console.log('Error saving address', err);
		}

		dispatch(actions.resetAddressForm(address.bp_id));
		dispatch(actions.loadBPAddressList(address.bp_id));
	},

	setActiveContact: (contact) => ({ type: types.SET_ACTIVE_CONTACT, contact }),

	setContactValues: (contact) => ({ type: types.SET_CONTACT_FORM_VALUES, contact }),

	saveContact: () => async (dispatch, getState) => {
		const state = getState().bp,
			contactForm = state.contactForm,
			activeContact = state.activeContact;

		const contact = {
			bp_id: activeContact.bp_id,
			contact_id: activeContact.contact_id,
			first_name: contactForm.first_name,
			last_name: contactForm.last_name,
			phone: contactForm.phone,
			email: contactForm.email,
		};

		dispatch(actions.setState({ contactSaving: true }));

		try {
			await api.put('/bp/contact', {
				contact: {
					...contact,
				},
			});

		} catch(err) {
			console.log('Error saving contact ', err);
		}

		dispatch(actions.resetContactForm(contact.bp_id));
		dispatch(actions.loadBPContactList(contact.bp_id));
	},

	resetContactForm: (cardCode) => async (dispatch) => {
		dispatch(actions.setState({ contactSaving: false }));
		dispatch(actions.resetContactFormValues());
		dispatch(actions.resetActiveContact());
		dispatch(actions.setActiveContact({ bp_id: cardCode }));
		dispatch(actions.setState({ isNewContact: true }));
	},

	resetAddressForm: (cardCode) => async (dispatch) => {
		dispatch(actions.setState({ addressSaving: false }));
		dispatch(actions.resetAddressFormValues());
		dispatch(actions.resetActiveAddress());
		dispatch(actions.setActiveAddress({ bp_id: cardCode }));
		dispatch(actions.setState({ isNewAddress: true }));
	},

	load_required_data: () => async(dispatch) => {
		dispatch(actions.setState({ loading: true }));

		try {
			let [locations, salesPersons] = await Promise.all([
				api.get('/bp/locations'),
				api.get('/sap/sales-persons'),
			]);

			let { countries, states } = locations;


			dispatch(actions.setState({
				countries,
				states,
				salesPersonsList: salesPersons.sales_persons,
			}));
		} catch (err) {
			console.error(err);
		}
	},

	copy_address: (type, record, mode) => async(dispatch, getState) => {

		let updateValues;
		if(mode === 'copy'){
			//reset name and attn when copying address
			updateValues = {
				...record,
				type: type, //carry type over to modal
				bp_id: '',
				bp_name: '',
				address_name: '',
				address_attn: '',
			};
		}

		if(mode === 'edit'){
			updateValues = {
				...record,
				type: type, //carry type over to modal
			};
		}

		dispatch(actions.setAddressValues(updateValues));
	},

	copy_contact: (record, mode) => async(dispatch) => {
		let updateValues;

		if( mode === 'edit' ){
			updateValues = {
				...record,
				is_default: record.is_default === 'Y' ? 'Y' : '',
			};
		} else {
			updateValues = {
				...record,
				is_default: '',
			};
		}

		console.log(updateValues);
		dispatch(actions.setContactValues(updateValues));
	},

	check_address_name: (address, address_name, type) => async (dispatch, getState) => {
		let state = getState().bp,
		bp_id	  = state.bpHeader.bp_id,
		billing   = state.bpBillingAddress,
		shipping  = state.bpShippingAddress,
		isValid   = checkRequiredInputs(address, 'address');

		if (!isValid) {
            message.error('Fields marked with a * are required');
            return;
        }

		try {
			
			let addresses = await api.get('/bp/address_name', {
					params: {
						cardCode: bp_id ? bp_id : '',
						address_name: address_name,
						type: type,
					},
			});

			//also check list of address to see if any new names are similar
			let checkState = (addressesType, name) => {

				let addressToCheck;
				let dupes = [];

				if(addressesType === 'B'){
					addressToCheck = billing;
				} else {
					addressToCheck = shipping;
				}

				for(let i = 0; i < addressToCheck.length; i++){
					const address = addressToCheck[i];
					if(address.address_name === name){
						dupes.push(address);
					}
				}

				if (dupes.length){
					return true;
				} else {
					return false;
				}

			};

			const nameInState = checkState(type, address_name);

			if(addresses.results.recordset.length || nameInState){
				message.error('This address name already exist.');
			} else {
				if(type === 'B'){
					dispatch(actions.add_billing_address(address));
				} else {
					dispatch(actions.add_shipping_address(address));
				}
				dispatch(actions.setState({
					addAddressVisible: false,
					addressCopy: false,
					addressEdit: false,
					addressType: '',
				}));
			}

		} catch (err) {
			console.error('Address Names Must be Unique ', err);
		}
	},

	check_bussiness_name: (name) => async (dispatch, getState) => {

		try {
			
			let names = await api.get('/bp/business_name', {
					params: {
						name: name,
					},
			});

			console.log(names)

			if(names.results.recordset.length){
				message.error('This business name already exist.');
				return false;
			} else {
				return true;
			}

		} catch (err) {
			console.error('Address Names Must be Unique ', err);
		}
	},

	
	get_warehouse_options_bp: () => async (dispatch) => {
		console.log('[BPManagement.redux] get_warehouse_options');
		dispatch(actions.setState({ loading: true }));
		
		api.get('/warehouse/get-warehouse-options-bp/').then(res => {
			let warehouseOptions = res.options;
			
			dispatch(actions.setState({ warehouseOptions }));
			dispatch(actions.setState({ loading: false }));
		}).catch(err => {
			console.error(err);
			dispatch(actions.setState({ loading: false }));
		});
		
	},

	load_statement_document: (docType, docEntry) => async(dispatch, getState) => {
		const response = await api.get('/orders/order-document', {
			params: {
				order_id: docEntry,
				type: docType, // TODO
			},
		});

		let fileData = response.document.FileData;
		fileData = base64toPDF(fileData);

		const fileURL = URL.createObjectURL(fileData);

		if (fileData) {
			window.open(fileURL);
		} else {
			message.error(`Could not load report for document ${docEntry}`);
		}


	},

};

function checkRequiredInputs(record, tableType) {
	let valid    = true;
	let required;
	console.log('[BPManagement.redux] checkRequiredInputs');
	//console.log('tableType: ' + tableType);
	console.log('record: ', record);
	if (tableType === 'address'){
		required = [
			'address_name',
			'address_attn',
			'address_line_1',
			'address_city',
			'address_state',
			'address_zip',
			'address_country',
		];
	} else if (tableType === 'header'){
		required = [
			'bp_name',
			'bp_type',
			'sales_employee_id',
			// TODO_REQUIRE_WAREHOUSE should this be required?  'default_warehouse',
		];
	} else {
		required = [
			'contact_name',
			'email',
		];
	}

	for (let i = 0; i < required.length; i++) {
		const value = required[ i ];
		if (record[value] === '' || isNull(record[value]) || isUndefined(record[value])) {
			valid = false;
		}
	}
	return valid;
}

//This function will check if theres a default/is_default of whatever dataset passed in 
//to use for verification when saving bp to make sure there is at least one record and default.
function checkRequiredDefault(bpData){

	if(bpData.length === 0){
		return false;
	}

	let hasDefault = [];

	for(let i = 0; i < bpData.length; i++){
		if(bpData[i].is_default === 'Y'){
			hasDefault.push(bpData[i]);
		}
	}

	return hasDefault.length >= 1;
}

//propbably can refactor this
//function that takes in array of objects (contact, billing address or shipping address) and returns default record
function getDefault(bpData){
	if(bpData.length === 0){
		return false;
	}
	let hasDefault = [];
	for(let i = 0; i < bpData.length; i++){
		if(bpData[i].is_default === 'Y'){
			hasDefault.push(bpData[i]);
		}
	}
	return hasDefault;
}

export default actions;
