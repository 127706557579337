import React, { lazy } from 'react';
import { actions, reducers } from './ProductConfigurator.redux';
import { actions as ordersActions, reducers as ordersReducers } from '../OrderEntry/OrderEntry.redux';
import { actions as modelsActions, reducers as modelsReducers } from './Models/Models.redux';
import {
	actions as modelDefinitionsActions,
	reducers as modelDefinitionsReducers,
} from './ModelDefinitions/ModelDefinitions.redux';
import {
	actions as skuManagerActions,
	reducers as skuManagerReducers,
} from './Models/ModelSKUManager/ModelSKUManager.redux';
import { registerReduxStore } from 'core/store';
import { registerRoutes } from 'core/routes';
import { registerNavigation } from 'core/navigation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShirt } from '@fortawesome/free-solid-svg-icons';



const Properties           = lazy(() => import('./Properties')),
	  OptionSets           = lazy(() => import('./OptionSets')),
	  ModelDefinitions     = lazy(() => import('./ModelDefinitions')),
	  ModelDefinitionsForm = lazy(() => import('./ModelDefinitions/ModelDefinitionsForm')),
	  Models               = lazy(() => import('./Models')),
	  ModelsForm           = lazy(() => import('./Models/ModelsForm')),
	  ModelSKUManager      = lazy(() => import('./Models/ModelSKUManager'));


// this is TEMPORARY for use of the BP serach selection modal within model defs
// Need to make BP selection a reusable drop-in component. Facepalm
registerReduxStore('orders', ordersReducers, ordersActions);

registerReduxStore('configurator', reducers, actions);
registerReduxStore('configurator_models', modelsReducers, modelsActions);
registerReduxStore('configurator_definitions', modelDefinitionsReducers, modelDefinitionsActions);
registerReduxStore('configurator_sku_manager', skuManagerReducers, skuManagerActions);

registerRoutes([
	{
		path: '/configurator/option-sets',
		exact: true,
		render: <OptionSets />,
		title: 'Configurator: Option Sets',
		private: true,
		permissions: [],
	},
	{
		path: '/configurator/properties',
		exact: true,
		render: <Properties />,
		title: 'Configurator: Properties',
		private: true,
		permissions: [],
	},


	{
		path: '/configurator/model-definitions',
		exact: true,
		render: <ModelDefinitions />,
		title: 'Configurator: Model Definitions',
		private: true,
		permissions: [],
	},
	{
		path: '/configurator/model-definitions/edit/:modeldef_id',
		exact: true,
		render: <ModelDefinitionsForm mode="edit" />,
		title: 'Configurator: Model Definitions',
		private: true,
		permissions: [],
	},
	{
		path: '/configurator/model-definitions/new',
		exact: true,
		render: <ModelDefinitionsForm mode="new" />,
		title: 'Configurator: Model Definitions',
		private: true,
		permissions: [],
	},


	{
		path: '/configurator/models',
		exact: true,
		render: <Models />,
		title: 'Configurator: Models',
		private: true,
		permissions: [],
	},
	{
		path: '/configurator/models/new',
		exact: true,
		render: <ModelsForm />,
		title: 'Configurator: New Model',
		private: true,
		permissions: [],
	},
	{
		path: '/configurator/models/skus/:model_id',
		exact: true,
		render: <ModelSKUManager />,
		title: 'Configurator: SKU Manager',
		private: true,
		permissions: [],
	},
]);

registerNavigation({
	title: 'Configurator',
	permissions: [],
	single: false,
	top: true,
	home: true,
	icon: <FontAwesomeIcon icon={faShirt} />,
	children: [
		{
			title: 'Models',
			url: '/configurator/models',
			permissions: [],
		},
		{
			title: 'Model Definitions',
			url: '/configurator/model-definitions',
			permissions: [],
		},
		{
			title: 'Properties',
			url: '/configurator/properties',
			permissions: [],
		},
		{
			title: 'Option Sets',
			url: '/configurator/option-sets',
			permissions: [],
		},
	],
});




console.log('Registered Product Configurator Plugin');
