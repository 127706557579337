import api from 'api';
import Cookies from 'portal-cookie';
import getResponseErrors from 'lib/getResponseErrors';
import { actions as permissionActions } from './permissions.redux';
import { notification } from 'antd';


const initialState = {
	loggedIn:        false,
	pending:         false,
	showRelog:       false,
	errors:          [],
	resetToken:      null,
	verifyingToken:  false,
	showSignupModal: false,
};


export const types = {
	SET_STATE: 'AUTH/SET_STATE',
};




const reducers = (state = initialState, action) => {
	switch(action.type) {
		case types.SET_STATE:
			return { ...state, ...action.data };
		default:
			return state;
	}
};

export default reducers;



export const actions = {
	setState: (data) => ({ type: types.SET_STATE, data }),

	heartbeat: (data) => async (dispatch, getState) => {
		console.log('[auth.redux] heartbeat');
		try {
			let heartbeat = await api.get('/heartbeat/heartbeat');

			// console.log(heartbeat);
			// console.log(heartbeat.alive);

			if (heartbeat && heartbeat.alive) {
				console.log('SUCCESS service is alive');
				notification.destroy();
				notification['success']({
					duration: 10,
					message: 'Account Service connection: ',
					description:
						'Established',
				});
			} else {
				throw ('Service is not reachable');
			}

		} catch (err) {
			console.error('service is NOT alive');
			console.error(err);
			notification.destroy();
			notification['error']({
				duration: 10,
				message: 'Error: Service is not reachable',
				description:
					'Contact your IT or System Admin',
			});
		}
	},

	handleLogin: (data) => async (dispatch, getState) => {
		dispatch({ type: types.SET_STATE, data: { pending: true, errors: [] } });

		try {
			let res = await api.post('/user/login', {
				user: data.username,
				pass: data.password,
			});

			if(res.force_reset) {
				dispatch({
					type: types.SET_STATE,
					data: {
						pending:            false,
						errors:             [],
						loggedIn:           false,
						showPasswordChange: true,
						resetToken:         res.token,
					},
				});
			} else {
				dispatch(permissionActions.setState(res.permissions || {}));
				dispatch({ type: types.SET_STATE, data: { ...initialState, loggedIn: true } });
				Cookies.set('authToken', res.token);
			}

		} catch(err) {
			dispatch({ type: types.SET_STATE, data: { pending: false, errors: getResponseErrors(err) } });
		}
	},



	handlePasswordChange: (data) => async (dispatch, getState) => {
		dispatch({ type: types.SET_STATE, data: { pending: true, errors: [] } });

		if(data.password_reset1 !== data.password_reset2) {
			return dispatch({
				type: types.SET_STATE,
				data: { pending: false, errors: [ 'Password fields must match.' ] },
			});
		}

		try {
			await api.post('/user/change_password', {
				token: getState().auth.resetToken,
				pass:  data.password_reset1,
			});

			dispatch({
				type: types.SET_STATE,
				data: {
					pending:            false,
					errors:             [],
					loggedIn:           false,
					showPasswordChange: false,
					showRelog:          true,
					resetToken:         null,
				},
			});


		} catch(err) {
			dispatch({ type: types.SET_STATE, data: { pending: false, errors: getResponseErrors(err) } });
		}
	},



	verifyToken: () => async (dispatch) => {
		dispatch({ type: types.SET_STATE, data: { pending: true, loggedIn: false, verifyingToken: true, errors: [] } });

		try {
			let res = await api.get('/user/verify_token');

			dispatch(permissionActions.setState(res.permissions || {}));
			dispatch({
				type: types.SET_STATE,
				data: {
					...initialState,
					loggedIn:       true,
					verifyingToken: false,
				},
			});

		} catch(err) {
			dispatch({
				type: types.SET_STATE,
				data: { verifyingToken: false, pending: false, errors: getResponseErrors(err) },
			});
			Cookies.remove('authToken');
		}
	},
};
