import api from 'api';
import ManageProductionDocs from './ManageProductionDocs';
import { message } from 'antd';

let typesRaw = {
	SET_STATE: 'SET_STATE',
	RESET_ALL: 'RESET_ALL',
	SET_DOCUMENT_DATA: 'SET_DOCUMENT_DATA',
	SET_LINE_DATA: 'SET_LINE_DATA',
};


export const types = Object.keys(typesRaw).reduce((all, key) => ({
	...all,
	[key]: `PRODUCTION/${typesRaw[ key ]}`,
}), {});



const initialState = {
	loading: false,
	saving: false,

	documents: [],
};


export const reducers = (state = initialState, action) => {

	switch (action.type) {
		case types.SET_STATE:
		{
			return {...state, ...action.data };
		}


		case types.RESET_ALL:
		{
			return { ...initialState };
		}


		// document (header) data can be modified on either suggested or existing docs
		case types.SET_DOCUMENT_DATA:
		{
			return {
				...state,
				[action.documentsType]: [
					...state[action.documentsType].slice(0, action.documentIndex),
					{
						...state[action.documentsType][action.documentIndex],
						...action.data,
					},
					...state[action.documentsType].slice(action.documentIndex + 1),
				],
			};
		}

		// line data can only be modified on a suggested document, not one that has been saved
		case types.SET_LINE_DATA:
		{
			return {
				...state,
				suggestedDocs: [
					...state.suggestedDocs.slice(0, action.documentIndex),
					{
						...state.suggestedDocs[action.documentIndex],
						...action.data,
					},
					...state.suggestedDocs.slice(action.documentIndex + 1),
				],
			};
		}


		default:
			return state;

	}
};

export default reducers;


export const actions = {
	setState: (data) => ({ type: types.SET_STATE, data }),


	reset_all: () => ({ type: types.RESET_ALL }),


	set_document_data: (documentIndex, documentsType, data) => ({ type: types.SET_DOCUMENT_DATA, documentIndex, documentsType, data}),


	set_document_line_data: (documentIndex, lineIndex, data) => ({ type: types.SET_LINE_DATA, documentIndex, lineIndex, data }),

	save_production_orders: () => async (dispatch, getState) => {


		const state          = getState(),
			order_id       = state.purchasing.order_id,
			sap_id         = state.purchasing.docEntry,
			finished_goods = state.purchasing.lines,
			bp_name        = state.purchasing.bp_name,
			header         = state.purchasing.header;
		dispatch(actions.setState({ saving: true }));

		// any finished goods line that has BOMs will need to have production orders made against it
		const production_lines = finished_goods.filter(line => line.extras?.bom_children?.length > 0);

		if (production_lines.length > 0) {
			try {
				let production = {
					order_id: order_id,
					sap_id: sap_id, // 
					production_lines,
					bp_name: bp_name,
					bp_id: header.bp_id,
					due_date: header.due_date,
					production_notes: header.production_notes,
				};
			
				let prodDocResponse = await api.post('/orders/production/create-order', { 
					...production,
				});

				//console.log('PROD DOC Response', prodDocResponse);
				if(prodDocResponse.success.length === 0) {
					message.error('These production orders have already been created.');
				} else {
					message.success(`Created ${prodDocResponse.success.length} production orders successfully`);
				}
				if (prodDocResponse.errors.length > 0) {
					message.error(`Encountered errors: ${JSON.stringify(prodDocResponse.errors)}`);
				}
				/**
				 * @todo TODO - if the documents are created, need to change button to "Update Production Orders" - or maybe for now disable the button.
				 * Not sure if they will update or delete/re-create
				 */
			} catch(err) {
				console.error('ERROR CREATING PRODUCTION ORDER:');
				console.error(err);
				message.error(`Error creating production order(s): ${err}`);
			}
		} else {
			message.info('Production does not apply to this order');
		}

		dispatch(actions.setState({ saving: false }));
	},

};
