import { useDispatch, useSelector } from 'react-redux';

import { actions } from './Production.redux';
import { Button } from 'antd';
import React from 'react';

const ManageProductionDocs = () => {
	const dispatch = useDispatch(),
		  saving   = useSelector(state => state.production.saving);

	// add a useEffect hook to check for the needed info (order id, lines, sap id, etc) and reload if necessary


	return (

		<Button
			type="default"
			size="large"
			className="ts_full_width ts_margin_top"
			disabled={saving}
			loading={saving}
			onClick={() => {
				dispatch(actions.save_production_orders());
			}}
		>
			Create Production Orders
		</Button>
	)
}

export default ManageProductionDocs;
