import api from 'api';
import { actions as layoutActions } from 'core/Layout/Layout.redux';
import { push } from 'connected-react-router';
import { notification } from 'antd';
import React from 'react';



let typesRaw = {
	SET_STATE: 'SET_STATE',
	SET_CODE_TYPE: 'SET_CODE_TYPE',
	SET_MODEL_CODE: 'SET_MODEL_CODE',
	SET_MODEL_STARTING_NUMBER: 'SET_MODEL_STARTING_NUMBER',
	RESET_ALL: 'RESET_ALL',
	PROPERTY_ADD_TO_GROUP: 'PROPERTY_ADD_TO_GROUP',
	PROPERTY_REASSIGN_GROUP: 'PROPERTY_REASSIGN_GROUP',
	PROPERTY_REMOVE_FROM_GROUP: 'PROPERTY_REMOVE_FROM_GROUP',
	SET_STEP: 'SET_STEP',
	SET_FORMAT_DATA: 'SET_FORMAT_DATA',
	SET_FORMAT_LABELS: 'SET_FORMAT_LABELS',
	SET_FORMAT_PADDING: 'SET_FORMAT_PADDING',
	SET_FORMAT_SEPARATORS: 'SET_FORMAT_SEPARATORS',
	CHANGE_OPTION_SET_SELECTION: 'CHANGE_OPTION_SET_SELECTION',
	PROPERTIES_CORRECT_EMPTY_GROUP: 'PROPERTIES_CORRECT_EMPTY_GROUP',
	SET_SEARCH_VALUES: 'SET_SEARCH_VALUES',
};

export const types = Object.keys(typesRaw).reduce((all, key) => ({
	...all,
	[ key ]: `PRODUCT_CONFIGURATOR/MODEL_DEFINITIONS/${typesRaw[ key ]}`,
}), {});



const initialState = {
	loading: false,
	loaded_defaults: false,

	search: {
		search_string: '',
		search_category: null,
		search_active: null,
		rows: [],
		total_rows: 0,
		page_number: 0,
		page_size: 10,
	},

	properties: [],
	properties_total_rows: 0,
	selected_properties_sku1: [],
	selected_properties_sku2: [],
	selected_properties_other: [],
	property_visible_popover: null,

	step: 1,
	maxStep: 1,
	errors: {
		//modeldef_code: 'The code you entered already exists.',
	},
	hasErrors: false,
	defaults: {},
	selected_bp_name: '',
	selected_bp_code: '',

	modeldef_id: null,
	modeldef_label: '',
	modeldef_code: '',
	modeldef_code_type: 'multi',
	modeldef_sku_format_noprop: null, // string
	modeldef_sku_format_oneprop: null, // string
	modeldef_sku_format_twoprop: null, //string
	modeldef_foreign_format: null, // string
	modeldef_next_sku: 1,
	modeldef_next_sku_loaded: 1, // Prevent going under this number when editing
	modeldef_padding_length: null, // integer
	modeldef_padding_pattern: null, // string
	modeldef_attachment_path: null, // string
	modeldef_notes: '',
	category_id: null,
	sales_property:null,
	category_label: 'None',

	formatter_options: {
		labels: {
			model_base: 'MODELBASE',
			model_number: 1,
			property_1: [ 'PROP1' ],
			property_2: [ 'PROP2' ],
		},
		padding: {
			model_number: { pattern: '0', length: 4 },
			property_1: { pattern: '0', length: 0 },
			property_2: { pattern: '0', length: 0 },
		},
		separators: {
			modelbase_modelnum: '',
			modelcode_prop1: '-',
			prop1_prop2: '-',
			prop1_inner: '-',
			prop2_inner: '-',
		},
		availableSeparators: [
			{ value: '', label: 'No Separator', display: '' },
			{ value: '-', label: '- (Hyphen)', display: '-' },
			{ value: '_', label: '_ (Underscore)', display: '_' },
			{ value: ' ', label: ' (Space)', display: ' ' },
		],
		showExampleStringNoProp: true,
		showExampleStringOneProp: true,
		showExampleStringTwoProp: true,
		useModelNumber: true,
		showOneProp: true,
		showTwoProps: true,
		disallowedCharacters: [
			'*', '{', '}', '%', '!', '^', '=', '<', '>', '?', '|',
		],
	},
};




export const reducers = (state = initialState, action) => {
	switch(action.type) {
		case types.SET_STATE: {
			return { ...state, ...action.data };
		}


		case types.SET_CODE_TYPE: {
			return {
				...state,
				modeldef_code_type: action.codeType,
				formatter_options: {
					...state.formatter_options,
					useModelNumber: action.codeType === 'multi',
				},
			};
		}


		case types.SET_MODEL_CODE: {
			return {
				...state,
				modeldef_code: action.modelCode,
				formatter_options: {
					...state.formatter_options,
					labels: {
						...state.formatter_options.labels,
						model_base: action.modelCode,
					},
				},
			};
		}


		case types.SET_MODEL_STARTING_NUMBER: {
			return {
				...state,
				modeldef_next_sku: action.startingNumber,
				formatter_options: {
					...state.formatter_options,
					labels: {
						...state.formatter_options.labels,
						model_number: action.startingNumber,
					},
				},
			};
		}


		case types.PROPERTY_ADD_TO_GROUP: {
			let properties = [
					...state[ `selected_properties_${action.group}` ],
					{ ...action.property },
				],
				newState   = {
					...state,
					[ `selected_properties_${action.group}` ]: properties,
				};

			if([ 'sku1', 'sku2' ].includes(action.group)) {
				newState.formatter_options = {
					...state.formatter_options,
					labels: {
						...state.formatter_options.labels,
						...generateLabels(newState.selected_properties_sku1, newState.selected_properties_sku2),
					},
				};
			}

			if(!newState.formatter_options.labels.property_1.length) {
				newState.formatter_options.labels.property_1 = [ 'PROPERTY1' ];
			}
			if(!newState.formatter_options.labels.property_2.length) {
				newState.formatter_options.labels.property_2 = [ 'PROPERTY2' ];
			}


			return newState;
		}


		case types.PROPERTY_REMOVE_FROM_GROUP: {
			let groupProp = `selected_properties_${action.group}`,
				newState  = {
					...state,
					[ groupProp ]: state[ groupProp ].filter(prop => prop.property_id !== action.property_id),
				};

			if([ 'sku1', 'sku2' ].includes(action.group)) {
				newState.formatter_options = {
					...state.formatter_options,
					labels: {
						...state.formatter_options.labels,
						...generateLabels(newState.selected_properties_sku1, newState.selected_properties_sku2),

					},
				};
			}

			if(!newState.formatter_options.labels.property_1.length) {
				newState.formatter_options.labels.property_1 = [ 'PROP1' ];
			}
			if(!newState.formatter_options.labels.property_2.length) {
				newState.formatter_options.labels.property_2 = [ 'PROP2' ];
			}

			return newState;
		}


		case types.PROPERTY_REASSIGN_GROUP: {
			let sourceGroup      = `selected_properties_${action.sourceGroup}`,
				destinationGroup = `selected_properties_${action.destinationGroup}`,
				property         = state[ sourceGroup ].find(prop => prop.property_id === action.property_id);

			if(!property) {
				return state;
			}

			let newState = {
				...state,
				[ sourceGroup ]: state[ sourceGroup ].filter(prop => prop.property_id !== action.property_id),
				[ destinationGroup ]: [
					...state[ destinationGroup ],
					{ ...property },
				],
			};

			newState.formatter_options = {
				...state.formatter_options,
				labels: {
					...state.formatter_options.labels,
					...generateLabels(newState.selected_properties_sku1, newState.selected_properties_sku2),
				},
			};

			if(!newState.formatter_options.labels.property_1.length) {
				newState.formatter_options.labels.property_1 = [ 'PROPERTY1' ];
			}
			if(!newState.formatter_options.labels.property_2.length) {
				newState.formatter_options.labels.property_2 = [ 'PROPERTY2' ];
			}



			return newState;
		}


		case types.PROPERTIES_CORRECT_EMPTY_GROUP: {
			if(state.selected_properties_sku2.length && !state.selected_properties_sku1.length) {
				let newState = {
					...state,
					selected_properties_sku1: [ ...state.selected_properties_sku2 ],
					selected_properties_sku2: [],
				};

				newState.formatter_options = {
					...state.formatter_options,
					labels: {
						...state.formatter_options.labels,
						...generateLabels(newState.selected_properties_sku1, newState.selected_properties_sku2),
					},
				};

				if(!newState.formatter_options.labels.property_1.length) {
					newState.formatter_options.labels.property_1 = [ 'PROPERTY1' ];
				}
				if(!newState.formatter_options.labels.property_2.length) {
					newState.formatter_options.labels.property_2 = [ 'PROPERTY2' ];
				}

				return newState;
			} else {
				return state;
			}
		}


		case types.RESET_ALL: {
			return { ...initialState };
		}


		case types.SET_STEP: {
			// No step changing if loading/saving
			if(state.loading) {
				return state;
			}

			// Go ahead
			return {
				...state,
				step: action.step,
				maxStep: state.maxStep > action.step
					? state.maxStep
					: action.step,
			};
		}


		case types.SET_FORMAT_DATA: {
			return {
				...state,
				formatter_options: {
					...state.formatter_options,
					...action.data,
				},
			};
		}


		case types.SET_FORMAT_LABELS: {
			return {
				...state,
				formatter_options: {
					...state.formatter_options,
					labels: {
						...state.formatter_options.labels,
						...action.data,
					},
				},
			};
		}


		case types.SET_FORMAT_PADDING: {
			return {
				...state,
				formatter_options: {
					...state.formatter_options,
					padding: {
						...state.formatter_options.padding,
						...action.data,
					},
				},
			};
		}


		case types.SET_FORMAT_SEPARATORS: {
			return {
				...state,
				formatter_options: {
					...state.formatter_options,
					separators: {
						...state.formatter_options.separators,
						...action.data,
					},
				},
			};
		}


		case types.CHANGE_OPTION_SET_SELECTION: {
			return changeOptionSetSelection(state, action);
		}


		case types.SET_SEARCH_VALUES: {
			return {
				...state,
				search: {
					...state.search,
					...action.data,
				},
			};
		}

		default: {
			return state;
		}
	}
};

export default reducers;



export const actions = {
	setState: (data) => ({ type: types.SET_STATE, data }),



	reset_all: () => ({ type: types.RESET_ALL }),



	set_category: (value, label) => ({ type: types.SET_STATE, data: { category_id: value, category_label: label } }),



	setCodeType: (codeType) => ({ type: types.SET_CODE_TYPE, codeType }),



	setModelCode: (modelCode) => ({ type: types.SET_MODEL_CODE, modelCode }),



	setModelStartingNumber: (startingNumber) => ({ type: types.SET_MODEL_STARTING_NUMBER, startingNumber }),


	defaults_get: (params = {}) => async (dispatch) => {
		dispatch(actions.setState({ loading: true }));

		try {
			let requests = [
					api.get('/configurator/model-definitions/defaults'),
					api.get('/configurator/model-definitions/properties'),
				],
				[
					defaults,
					properties,
				]        = await Promise.all(requests);


			dispatch({
				type: types.SET_STATE, data: {
					loading: false,
					loaded_defaults: true,
					defaults: defaults.defaults,
					properties: properties.properties,
					properties_total_rows: properties.properties.length,
					selected_properties_other: properties.properties.filter(prop => prop.property_required === true),
					formatter_options: {
						...initialState.formatter_options,
						...defaults.defaults.formatter_defaults,
					},
				},
			});
		} catch(err) {
			dispatch(actions.setState({ loading: false }));
		}
	},



	property_add_to_group: (group, property) => ({
		type: types.PROPERTY_ADD_TO_GROUP,
		group,
		property,
	}),



	property_remove_from_group: (property_id, group) => ({
		type: types.PROPERTY_REMOVE_FROM_GROUP,
		property_id,
		group,
	}),



	property_reassign_group: (property_id, sourceGroup, destinationGroup) => ({
		type: types.PROPERTY_REASSIGN_GROUP,
		property_id,
		sourceGroup,
		destinationGroup,
	}),



	properties_correct_empty_group: () => ({
		type: types.PROPERTIES_CORRECT_EMPTY_GROUP,
	}),



	setStep: (step) => ({
		type: types.SET_STEP,
		step,
	}),



	setFormatData: (data) => ({
		type: types.SET_FORMAT_DATA,
		data,
	}),



	setFormatLabels: (data) => ({
		type: types.SET_FORMAT_LABELS,
		data,
	}),



	setFormatPadding: (data) => ({
		type: types.SET_FORMAT_PADDING,
		data,
	}),



	setFormatSeparators: (data) => ({
		type: types.SET_FORMAT_SEPARATORS,
		data,
	}),



	change_option_set_selection: (property_id, optset_id, checked) => ({
		type: types.CHANGE_OPTION_SET_SELECTION,
		property_id,
		optset_id,
		checked,
	}),



	save_model_definition: () => async (dispatch, getState) => {
		dispatch(actions.setState({ loading: true }));

		const state   = getState().configurator_definitions,
			  payload = {
				  modeldef_id: state.modeldef_id,
				  modeldef_label: state.modeldef_label,
				  modeldef_code: state.modeldef_code,
				  modeldef_code_type: state.modeldef_code_type,
				  modeldef_next_sku: state.modeldef_next_sku,
				  modeldef_padding_config: state.formatter_options.padding,
				  modeldef_separator_config: state.formatter_options.separators,
				  modeldef_properties_sku1: state.selected_properties_sku1.map(property => ({
					  property_id: property.property_id,
					  option_sets: property.option_sets
						  .filter(optset => optset.selected)
						  .map(optset => optset.optset_id),
				  })),
				  modeldef_properties_sku2: state.selected_properties_sku2.map(property => ({
					  property_id: property.property_id,
					  option_sets: property.option_sets
						  .filter(optset => optset.selected)
						  .map(optset => optset.optset_id),
				  })),
				  modeldef_properties_other: state.selected_properties_other.map(property => ({
					  property_id: property.property_id,
					  option_sets: property.option_sets
						  .filter(optset => optset.selected)
						  .map(optset => optset.optset_id),
				  })),
				  category_id: state.category_id,
				  sales_property:state.sales_property,
				  active: true,
			  };

		try {
			let result = await api.put('/configurator/model-definitions/save', {
				model_definition: payload,
			});

			if(result.modeldef_code_type === 'single' && result.model_id) {
				notification.success({
					placement: 'top',
					message: 'Model Created Successfully',
					duration: 8,
					description: (
						<span>
							Since your model definition was single-use, the resulting model was created automatically.
							You may now manage the SKUs for your new model.
						</span>
					),
				});

				dispatch(push(`/configurator/models/skus/${result.model_id}`));
				dispatch(actions.setState({ loading: false, modeldef_id: result.modeldef_id }));
			} else {
				notification.success({
					placement: 'top',
					message: 'Model Definition Created Successfully',
					duration: 8,
					description: (
						<span>
							Model definition {state.modeldef_code} was created successfully. If you would like to
							create a new model with this definition, <a
								onClick={(e) => {
									e.preventDefault();
									dispatch(push('/configurator/models/new'));
								}}
							>
								click here
							</a>.
						</span>
					),
				});

				dispatch(push('/configurator/model-definitions'));
				dispatch(actions.setState({ loading: false, modeldef_id: result.modeldef_id }));
			}


		} catch(err) {
			console.error(err);
			dispatch(actions.setState({ loading: false }));
		}
	},



	set_search_values: (data) => ({
		type: types.SET_SEARCH_VALUES,
		data,
	}),



	model_definitions_get: (params = {}) => async (dispatch, getState) => {
		const state  = getState().configurator_definitions.search,
			  page   = state.page_number,
			  limit  = state.page_size,
			  search = state.search_string,
			  active = state.search_active,
			  category = state.search_category;

		dispatch(actions.setState({ loading: true }));

		let activeVal = active;

		if(activeVal === null) {
			activeVal = '';
		} else {
			activeVal = String(activeVal);
		}

		try {
			let results = await api.get('/configurator/model-definitions', {
				params: {
					page: page,
					limit: limit,
					search: search,
					category_id: category,
					active: activeVal,
					...params,
				},
			});

			dispatch({
				type: types.SET_SEARCH_VALUES, data: {
					rows: results.model_definitions,
					total_rows: results.total_rows,
				},
			});
		} catch(err) {
			// do nothing
		}

		dispatch(actions.setState({ loading: false }));
	},



	load_model_definition: (modeldef_id) => async (dispatch, getState) => {
		dispatch(actions.setState({ loading: true }));
		let state = getState().configurator_definitions;

		try {
			let requests = [
				api.get('/configurator/model-definitions/edit', {
					params: {
						modeldef_id: modeldef_id,
					},
				}),

				api.get('/configurator/model-definitions/defaults'),
			];

			let [ modelDef, defaults ]           = await Promise.all(requests),
				{ model_definition, properties } = modelDef;

			let newState = {
				loading: false,
				loaded_defaults: true,
				defaults: defaults.defaults,
				maxStep: 4,
				properties: properties,
				properties_total_rows: properties.length,

				modeldef_id: model_definition.modeldef_id,
				modeldef_code: model_definition.modeldef_code,
				modeldef_code_type: model_definition.modeldef_code_type,
				modeldef_label: model_definition.modeldef_label,
				modeldef_next_sku: model_definition.modeldef_next_sku,
				modeldef_next_sku_loaded: model_definition.modeldef_next_sku,
				category_id: model_definition.category_id,

				selected_properties_sku1: model_definition.modeldef_properties_sku1,
				selected_properties_sku2: model_definition.modeldef_properties_sku2,
				selected_properties_other: model_definition.modeldef_properties_other,


				formatter_options: {
					...state.formatter_options,
					...defaults.defaults.formatter_defaults,

					labels: {
						model_base: model_definition.modeldef_code,
						model_number: model_definition.modeldef_next_sku,
						property_1: [ 'PROP1' ],
						property_2: [ 'PROP2' ],
						...generateLabels(model_definition.modeldef_properties_sku1, model_definition.modeldef_properties_sku2),
					},

					padding: model_definition.modeldef_padding_config,
					separators: model_definition.modeldef_separator_config,
					use_model_number: model_definition.modeldef_code_type === 'multi',
				},
			};

			dispatch(actions.setState(newState));
			dispatch(layoutActions.setTitle('Configurator: Editing Model Definition ' + model_definition.modeldef_code));
		} catch(err) {
			// deal with it later
			dispatch(actions.setState({ loading: false }));
		}

	},

};




/*****************************
 *     REDUCER FUNCTIONS     *
 ****************************/
function changeOptionSetSelection(state, { property_id, optset_id, checked }) {

	function mutateProperties(prop) {
		// Check for a match
		if(prop.property_id === property_id && Array.isArray(prop.option_sets)) {
			prop.option_sets = prop.option_sets.map(optionSet => {
				if(optionSet.optset_id === optset_id) {
					// Found a match, return the new checked status
					return { ...optionSet, selected: checked };
				}

				// Deselect everything else
				return { ...optionSet, selected: false };
			});
		}

		// Return property as-is if we don't need to change anything
		return prop;
	}

	// Run all sku properties through mutateProperties to be safe.

	let newState = {
		...state,
		properties: state.properties.map(mutateProperties),
		selected_properties_sku1: state.selected_properties_sku1.map(mutateProperties),
		selected_properties_sku2: state.selected_properties_sku2.map(mutateProperties),
		selected_properties_other: state.selected_properties_other.map(mutateProperties),
	};

	newState.formatter_options = {
		...state.formatter_options,
		labels: {
			...state.formatter_options.labels,
			...generateLabels(newState.selected_properties_sku1, newState.selected_properties_sku2),
		},
	};

	if(!newState.formatter_options.labels.property_1.length) {
		newState.formatter_options.labels.property_1 = [ 'PROPERTY1' ];
	}
	if(!newState.formatter_options.labels.property_2.length) {
		newState.formatter_options.labels.property_2 = [ 'PROPERTY2' ];
	}

	return newState;
}

// OLD VERSION, ALLOWED MULTIPLE SELECTIONS
/*
function _OLDchangeOptionSetSelection(state, { property_id, optset_id, checked }) {

	function mutateProperties(prop) {
		// Set this to true and we can skip checking for more values
		let foundMatch = false;

		// Check for a match and that we haven't already found a match
		if(prop.property_id === property_id && Array.isArray(prop.option_sets) && !foundMatch) {
			prop.option_sets = prop.option_sets.map(optionSet => {
				if(optionSet.optset_id === optset_id) {
					// Found a match, return the new checked status
					foundMatch = true;
					return { ...optionSet, selected: checked };
				}

				return optionSet;
			});
		}

		// Return property as-is if we don't need to change anything
		return prop;
	}

	// Run all sku properties through mutateProperties to be safe.

	let newState = {
		...state,
		properties: state.properties.map(mutateProperties),
		selected_properties_sku1: state.selected_properties_sku1.map(mutateProperties),
		selected_properties_sku2: state.selected_properties_sku2.map(mutateProperties),
		selected_properties_other: state.selected_properties_other.map(mutateProperties),
	};

	newState.formatter_options = {
		...state.formatter_options,
		labels: {
			...state.formatter_options.labels,
			...generateLabels(newState.selected_properties_sku1, newState.selected_properties_sku2),
		},
	};

	if(!newState.formatter_options.labels.property_1.length) {
		newState.formatter_options.labels.property_1 = [ 'PROPERTY1' ];
	}
	if(!newState.formatter_options.labels.property_2.length) {
		newState.formatter_options.labels.property_2 = [ 'PROPERTY2' ];
	}

	return newState;
}
 */


function generateLabels(property1 = [], property2 = []) {
	let result = {
		property_1: [],
		property_2: [],
	};

	property1.forEach(prop => {
		// Find the first valid value
		let validSet = prop.option_sets.find(optionSet => (optionSet.selected === true && optionSet.example_value));
		result.property_1.push(
			validSet
				? validSet.example_value || 'PROPERTY1'
				: 'PROPERTY1',
		);
	});

	property2.forEach(prop => {
		// Find the first valid value
		let validSet = prop.option_sets.find(optionSet => (optionSet.selected === true && optionSet.example_value));
		result.property_2.push(
			validSet
				? validSet.example_value || 'PROPERTY1'
				: 'PROPERTY1',
		);
	});


	return result;
}
