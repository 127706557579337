import React, { lazy } from 'react';
import { registerReduxStore } from 'core/store';
import { registerRoutes } from 'core/routes';
import { registerNavigation } from 'core/navigation';
import { TeamOutlined } from '@ant-design/icons';
import {actions as CustomerManagementActions} from '../BPManagement.redux';
import {reducers as CustomerManagementReducers} from '../BPManagement.redux';

const CustomerManagement = lazy(() => import('./CustomerManagementEntry'));
const CustomerManagementSearch = lazy(() => import('./CustomerManagementSearch'));

registerReduxStore('bp', CustomerManagementReducers, CustomerManagementActions);

registerRoutes([
	{
		path: '/customer-management',
		exact: true,
		render: <CustomerManagement mode="new" />,
		title: 'Customer Management: New',
		private: true,
		permissions: [],
	},
	{
		path: '/customer-management/edit/:cardCode',
		exact: true,
		render: <CustomerManagement mode="edit" />,
		title: 'Customer Management: Edit',
		private: true,
		permissions: [],
	},
    {
		path: '/customer-management/search',
		exact: true,
		render: <CustomerManagementSearch />,
		title: 'Customer Management: Search',
		private: true,
		permissions: [],
	},
]);

registerNavigation({
	title: 'Business Partners',
	permissions: [],
	single: false,
	top: true,
	home: true,
	icon: <TeamOutlined />,
	children: [
		{
			title: 'New Customer',
			url: '/customer-management',
			permissions: [],
		},
        {
			title: 'Search Customer',
			url: '/customer-management/search',
			permissions: [],
		},
	],
});
