import axios from 'axios';
import Cookies from 'portal-cookie';
import { message } from 'antd';
import getResponseErrors from 'lib/getResponseErrors';
import store, { actions } from 'core/store';


const apiInstance = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	timeout: 600000, // IMPORTANT
	withCredentials: true,
});

function requestInterceptor(config) {
	let token = Cookies.get('authToken');

	if(token) {
		config.headers['Authorization'] = `Bearer ${token}`;
	}

	return config;
}


function responseInterceptor(response) {
	try {
		if(response.data.token) {
			Cookies.set('authToken', response.data.token);
		}

		if(response.data.messages && Array.isArray(response.data.messages)) {
			for(let i = 0; i < response.data.messages.length; i++) {
				message.info(response.data.messages[i],  (i+1) * 2);
			}
		}
	} catch(err) {
		// nothing
	}


	return response.data;
}



function responseErrorInterceptor(error) {
	try {
		let errors = getResponseErrors(error);

		if(error.response.status === 401 && error.response.data && error.response.data.logout) {
			store.dispatch(actions.auth.setState({ loggedIn: false, errors: errors }));
			Cookies.remove('authToken');
		}

		if(error.response.status !== 401 && errors.length) {
			for(let i = 0; i < errors.length; i++) {
				message.error(errors[i], (i+1) * 2);
			}
		}

		return Promise.reject(error);
	} catch(err) {
		return Promise.reject(error);
	}

}


apiInstance.interceptors.response.use(responseInterceptor, responseErrorInterceptor);
apiInstance.interceptors.request.use(requestInterceptor);


export default apiInstance;
