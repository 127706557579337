

const initialState = {
	show_modal_item_search: false,
	item_search_results: [],
	item_search_source: 'sap',
	item_search_value: '',
	item_search_toal_rows: null,
	item_search_line_index: null,
	item_search_action_store: '',
	show_modal_model_items: false,
	modal_model_items_model_id: null,
};

export const types = {
    SET_STATE: 'ITEM_SEARCH_MODAL/SET_STATE',
	RESET_STATE: 'ITEM_SEARCH_MODAL/RESET_STATE',
};

export const reducers = (state = initialState, action) => {
	switch(action.type) {
        case types.SET_STATE:
            return { ...state, ...action.data };
		case types.RESET_STATE:
			return { ...initialState };
        default:
            return state;
    }
};


export const actions = {
	setState: (data) => ({ type: types.SET_STATE, data }),
    resetState: () => ({ type: types.RESET_STATE }),
	
};