/*

    NOT FINISHED

*/


import api from 'api';
import makeSorter from 'lib/makeSorter';
import { regexpSearch } from 'lib/searchFns';
import ___ from '../constants';
import { result } from 'lodash';

const dateColumns = [
    'est_arrival_date',
];

const initialState = {
    currentEditRow: null,
    currentEditHeader: null,
    originalEditRow: null,
    detailLines: null,
    productionOrders: [],
    failedDocEntries: [],
    errors: [],
    loading: false,
    currentDataSource: [],
    columns: [],
    filteredSource: [],
    searchedSource: [],
    sortOrder: false,
    sortField: false,
    sorting: [],
    filters: [],
    requestedPage: 0,
    forceReload: false,
    //warehousePerson: '',
    logged_in_rep: '',
    filterValues: {
        order_date_formatted: null,
        event_date: null,
        status: null,
    },
};

export const types = {
    SET_STATE: 'PRODUCTION/SET_STATE',
    SET_FILTER_VALUES: 'PRODUCTION/SETFILTERVALUES',
    START_LOADING: 'PRODUCTION/START_LOADING',
    CHANGE_FILTERS: 'PRODUCTION/CHANGE_FILTERS',
};

export const reducers = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_STATE:
            return { ...state, ...action.data };

        case types.SET_FILTER_VALUES:
            return {
                ...state,
                filterValues: {
                    ...state.filterValues,
                    ...action.data,
                },
            };

        case types.START_LOADING:
            return {
                ...state,
                requestedPage: action.data.requestedPage,
                take: action.data.take,
            };

        case types.CHANGE_FILTERS:
            return {
                ...state,
                forceReload: true,
                requestedSkip: 0,
                allOrders: [],
                filters: action.data.filters,
            };


        default:
            return state;
    }
};




export const actions = {
    setState: (data) => ({ type: types.SET_STATE, data }),

    setFilterValues: (data) => ({ type: types.SET_FILTER_VALUES, data }),

    startLoading: (data) => ({ type: types.START_LOADING, data }),

    setCurrentEditRow: (row) => async (dispatch, getState) => {
        //console.log('[Production.redux.js] setCurrentEditRow');
        //console.log(row);
        dispatch({
            type: types.SET_STATE,
            data: {
                currentEditRow: row,
            },
        });
    },

    getProductionOrders: () => async (dispatch, getState) => {
        console.log('[Production.redux.js] getProductionOrders()');
        const state = getState();
        dispatch(actions.setState({ loading: true }));

        try {
            // QUERY Warehouse.GetProductionOrderLines
            let result = await api.get('/warehouse/production');
            console.log(result)
            //console.log(result.productionOrders.recordset)
            let _result = result.productionOrders.recordset;
            //console.log(typeof _result)
            // console.log(typeof _result[0])
            //console.log(_result.length);

            const _arrayResult = [];
            for (let [key, value] of Object.entries(_result)) {
                //console.log(key, value);
                _arrayResult.push(value)
            }
            //console.log(_arrayResult)
            //console.log(typeof _arrayResult)

            dispatch(actions.setState({
                // productionOrders: [..._obj],
                // currentDataSource: [..._obj],
                // filteredSource: [..._obj],
                productionOrders: _arrayResult,
                currentDataSource: _arrayResult,
                filteredSource: _arrayResult,
            }));
        } catch (err) {
            console.error(err);
        }
        dispatch(actions.setState({ loading: false }));
    },

    searchCurrent: (searchValue) => (dispatch, getState) => {
        console.log('[Production.redux] searchCurrent ');

        let { filteredSource, sortOrder, sortField } = getState().warehouseProd;

        //console.log(filteredSource)
        //console.log(typeof filteredSource[0])
        let searchedSource = [];

        if (searchValue === '') {
            searchedSource = filteredSource;
        } else {
            for (let bp of filteredSource) {
                for (let prop in bp) {
                    if (regexpSearch(searchValue, bp[prop])) {
                        searchedSource.push(bp);
                        break;
                    }
                }
            }
        }

        dispatch(actions.setState({
            searchedSource: [...searchedSource],
            searchValue,
            hasFilterBeenApplied: true,
            hasOrder: true
        }));

        dispatch(actions.sortCurrent({
            field: sortField,
            order: sortOrder
        }));
    },

    sortCurrent: ({ field, order }) => (dispatch, getState) => {
        //console.log('[Production.redux] sortCurrent ')
        //console.log(field)
        //console.log(order)
        let { searchedSource, columns } = getState().warehouseProd;

        const numberSortColumns = [
            ...dateColumns,
            'card_code',
        ];

        let newColumns = columns.map((col) => {
            if (col.dataIndex === field) {
                return { ...col, sortOrder: order }
            } else {
                return { ...col, sortOrder: false }
            }
        });

        let productionOrders = searchedSource; // THIS

        if (field !== false) {
            let type = 'string';
            if (numberSortColumns.indexOf(field) !== -1) {
                type = 'number';
            }
            productionOrders = searchedSource.sort(makeSorter(type, field));
        }

        if (order === 'ascend') {
            productionOrders = productionOrders.reverse();
        }

        dispatch(actions.setState({
            columns: newColumns,
            productionOrders: [...productionOrders],
            sortOrder: order,
            sortField: field,
        }));
    },

    getProductionPRODetails: () => async (dispatch, getState) => {
        console.log('[Production.redux.js] getProductionPRODetails()');
        const state = getState().warehouseProd;
        dispatch(actions.setState({ loading: true }));
        console.log(state.currentEditRow);
        console.log(state.currentEditRow.docEntry);

        try {
            // QUERY Warehouse.GetPROFinishedGood
            let result = await api.get(`/warehouse/production/pro-details/${state.currentEditRow.docEntry}`, {
                params: {
                    docEntry: state.currentEditRow.docEntry,
                },
            });
            console.log(result);
            console.log(result.detailLines.recordset);

            dispatch(actions.setState({ detailLines: result.detailLines.recordset }));
        } catch (err) {
            console.error(err);
        }
        dispatch(actions.setState({ loading: false }));
    },

    submitToIssue: (rows, popSuccessCB, popFailureCB) => async (dispatch, getState) => {
        console.log('[Production.redux.js] submitToIssue()');
        const state = getState().warehouseProd;

        if (!rows || rows.length <= 0) {
            popFailureCB();
            return null;
        }
        //console.log(rows);

        // combine rows by base DocEntry so they can be on the same GoodsIssue
        function groupByDocEntry(array) {
            const groupedArrays = {};
            array.forEach(obj => {
                const _docEntry = obj.docEntry;
                if (!groupedArrays[_docEntry]) {
                    groupedArrays[_docEntry] = [];
                }
                groupedArrays[_docEntry].push(obj);
            });
            return Object.values(groupedArrays);
        }

        const groupedRows = groupByDocEntry(rows);
        // console.log(groupedRows);

        dispatch(actions.setState({ loading: true, failedDocEntries: [], errors: [] }));
        try {

            let failedDocEntries = [];
            let _errors = [];

            let result = await api.put('/warehouse/write-goods-issue',
                {
                    rows,
                    groupedRows,
                    //Comments: currentEditRow.notes,
                });
            //console.log(result);
            //console.log(result.data);
            //console.log(result.success);
            //console.log(result.responses);
            //console.log(result.statuses);

            let _failedResponses = result.responses.filter((rsp) => {
                //console.log(rsp);
                return !rsp.successful;
            });


            let _failedStatuses = result.statuses.filter((stat) => {
                //console.log(stat);
                return !stat.key;
            });

            if (_failedResponses.length > 0) {
                console.log('_failedResponses ');
                console.log(_failedResponses);
                //popFailureCB();
            }

            if (_failedStatuses.length > 0) {
                console.log('_failedStatuses ');
                console.log(_failedStatuses);
                _failedStatuses.forEach((failure) => {
                    //console.log(failure);
                    //console.log(failure.error);
                    failedDocEntries.push(failure.groupDocEntry);
                    _errors.push(failure.error[0]); // TODO_HARDCODE_ERROR_INDEX
                });
                //popFailureCB();
            }

            //console.log('failedDocEntries');
            //console.log(failedDocEntries);

            if (result.success) {
                console.log('submitToIssue success');
                popSuccessCB();
                dispatch(actions.setState({
                    loading: false,
                    failedDocEntries: failedDocEntries,
                    errors: _errors
                }));
                dispatch(actions.getProductionOrders());
            } else if (result.success === 'partial') {
                console.log('submitToIssue partial');
                popSuccessCB();
                // TODO what can we do with erroneous rows
                dispatch(actions.setState({
                    loading: false,
                    failedDocEntries: failedDocEntries,
                    errors: _errors,
                }));
                //dispatch(actions.getProductionOrders());
            } else {
                dispatch(actions.setState({
                    loading: false,
                    failedDocEntries: failedDocEntries,
                    errors: _errors,
                }));
            }

        } catch (err) {
            console.error((result & result.errorMsg) ? result.errorMsg : 'Could not Write');
            console.error(err);
            popFailureCB();
        }
        //dispatch(actions.setState({ loading: false }));
    },

    writeProductionOrder: (currentEditRow, popSuccessCB) => async (dispatch, getState) => {
        console.log('[Production.redux.js] writeProductionOrder()');

        console.log(currentEditRow);

        dispatch(actions.setState({ loading: true }));
        try {
            let result = await api.put('/warehouse/write-goods-receipt-pro',
                {
                    BaseDocEntry: currentEditRow.docEntry,
                    BaseDocType: 202, // OR currentEditRow.objType
                    Qty: currentEditRow.finishedQty, // NOTE this is different than the incoming qty
                    Comments: currentEditRow.notes,
                });
            console.log(result);
            //console.log(result.data);

            console.log(result.success);
            if (result.success) {
                console.log('success');
                popSuccessCB();
                dispatch(actions.getProductionOrders());
            }

        } catch (err) {
            console.error((result & result.errorMsg) ? result.errorMsg : 'Could not Write');
            console.error(err);
        }
        dispatch(actions.setState({ loading: false }));
    },

    writePurchaseOrder: (currentEditRow, editDetailsLines, popSuccessCB) => async (dispatch, getState) => {
        console.log('[Production.redux.js] writePurchaseOrder()');
        //console.log(currentEditRow);
        //console.log(editDetailsLines);

        const state = getState().warehouseProd;
        dispatch(actions.setState({ loading: true }));

        let _filtered = editDetailsLines.filter((r) => (r.orderQty && r.orderQty > 0));
        //console.log(_filtered);

        let sendBody = {
            BaseDocEntry: currentEditRow.docEntry,
            BaseDocType: 22, // OR currentEditRow.objType
            CardCode: currentEditRow.cardCode,
            submitRows: _filtered,
        };

        // TODO_BLANK_NOTES need to prevent writing comments if they are blank, except when they would be intentionally blank
        if (currentEditRow.notes && currentEditRow.notes !== '') {
            sendBody.Comments = currentEditRow.notes;
        }

        try {
            let result = await api.put('/warehouse/write-grpo',
                sendBody);
            console.log(result);
            console.log(result.data);

            //console.log(result.success);
            if (result.success) {
                console.log('success');
                popSuccessCB();
                dispatch(actions.getProductionOrders());
                //dispatch(actions.setState({ purchaseOrders: result.purchaseOrders.recordset }));
            }

            //console.log(result.errorMsg); // errorMsg: "One of the base documents has already been closed"

        } catch (err) {
            console.error((result & result.errorMsg) ? result.errorMsg : 'Could not Write');
            console.error(err);
        }
        dispatch(actions.setState({ loading: false }));
    },
};
