import api from 'api';
import makeSorter from 'lib/makeSorter';
import { regexpSearch } from 'lib/searchFns';
import ___ from '../constants';
import { result } from 'lodash';

const dateColumns = [
    'est_arrival_date',
]

const initialState = {
    currentEditRow: null,
    currentEditHeader: null,
    originalEditRow: null,
    detailLines: null,
    incomingOrders: [],
    loading: false,
    currentDataSource: [],
    modalType: null,
    columns: [],
    filteredSource: [],
    searchedSource: [],
    sortOrder: false,
    sortField: false,
    sorting: [],
    filters: [],
    requestedPage: 0,
    forceReload: false,
    //warehousePerson: '',
    logged_in_rep: '',
    filterValues: {
        order_date_formatted: null,
        event_date: null,
        status: null,
    },
};

export const types = {
    SET_STATE: 'INCOMING/SET_STATE',
    SET_FILTER_VALUES: 'INCOMING/SETFILTERVALUES',
    START_LOADING: 'INCOMING/START_LOADING',
    CHANGE_FILTERS: 'INCOMING/CHANGE_FILTERS',
};

export const reducers = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_STATE:
            return { ...state, ...action.data };

        case types.SET_FILTER_VALUES:
            return {
                ...state,
                filterValues: {
                    ...state.filterValues,
                    ...action.data,
                },
            };

        case types.START_LOADING:
            return {
                ...state,
                requestedPage: action.data.requestedPage,
                take: action.data.take,
            };

        case types.CHANGE_FILTERS:
            return {
                ...state,
                forceReload: true,
                requestedSkip: 0,
                allOrders: [],
                filters: action.data.filters,
            };


        default:
            return state;
    }
};




export const actions = {
    setState: (data) => ({ type: types.SET_STATE, data }),

    setFilterValues: (data) => ({ type: types.SET_FILTER_VALUES, data }),

    startLoading: (data) => ({ type: types.START_LOADING, data }),

    setCurrentEditRow: (row) => async (dispatch, getState) => {
        //console.log('[Incoming.redux.js] setCurrentEditRow');
        //console.log(row);
        dispatch({
            type: types.SET_STATE,
            data: {
                currentEditRow: row,
            },
        });
    },

    getIncoming: () => async (dispatch, getState) => {
        console.log('[Incoming.redux.js] getIncoming()');
        const state = getState();
        dispatch(actions.setState({ loading: true }));

        try {
            let result = await api.get('/warehouse/receiving');
            //console.log(result)
            //console.log(result.incomingOrders.recordset)
            let _result = result.incomingOrders.recordset;
            //console.log(typeof _result)
            // console.log(typeof _result[0])

            const _arrayResult = [];
            for (let [key, value] of Object.entries(_result)) {
                //console.log(key, value);
                _arrayResult.push(value)
            }
            //console.log(_arrayResult)
            //console.log(typeof _arrayResult)

            dispatch(actions.setState({
                // incomingOrders: [..._obj],
                // currentDataSource: [..._obj],
                // filteredSource: [..._obj],
                incomingOrders: _arrayResult,
                currentDataSource: _arrayResult,
                filteredSource: _arrayResult,
            }));
        } catch (err) {
            console.error(err);
        }
        dispatch(actions.setState({ loading: false }));
    },

    searchCurrent: (searchValue) => (dispatch, getState) => {
        console.log('[Incoming.redux] searchCurrent ');

        let { filteredSource, sortOrder, sortField } = getState().warehouse;

        //console.log(filteredSource)
        //console.log(typeof filteredSource[0])
        let searchedSource = [];

        if (searchValue === '') {
            searchedSource = filteredSource;
        } else {
            for (let bp of filteredSource) {
                for (let prop in bp) {
                    // TODO_SEARCH does not work with dates or status
                    if (regexpSearch(searchValue, bp[prop])) {
                        searchedSource.push(bp);
                        break;
                    }
                }
            }
        }

        dispatch(actions.setState({
            searchedSource: [...searchedSource],
            searchValue,
            hasFilterBeenApplied: true,
            hasOrder: true,
        }));

        dispatch(actions.sortCurrent({
            field: sortField,
            order: sortOrder
        }));
    },

    sortCurrent: ({ field, order }) => (dispatch, getState) => {
        //console.log('[Incoming.redux] sortCurrent ')
        //console.log(field)
        //console.log(order)
        let { searchedSource, columns } = getState().warehouse;

        const numberSortColumns = [
            ...dateColumns,
            'card_code',
        ];

        let newColumns = columns.map((col) => {
            if (col.dataIndex === field) {
                return { ...col, sortOrder: order }
            } else {
                return { ...col, sortOrder: false }
            }
        });

        let incomingOrders = searchedSource; // IMPORTANT

        if (field !== false) {
            let type = 'string';
            if (numberSortColumns.indexOf(field) !== -1) {
                type = 'number';
            }
            incomingOrders = searchedSource.sort(makeSorter(type, field));
        }

        if (order === 'ascend') {
            incomingOrders = incomingOrders.reverse();
        }

        dispatch(actions.setState({
            columns: newColumns,
            incomingOrders: [...incomingOrders],
            sortOrder: order,
            sortField: field
        }));
    },

    getIncomingPODetails: () => async (dispatch, getState) => {
        console.log('[Incoming.redux.js] getIncomingPODetails()');
        const state = getState().warehouse;
        dispatch(actions.setState({ loading: true }));

        //console.log(state.currentEditRow)
        //console.log(state.currentEditRow.docKey)
        //console.log(state.currentEditRow.docEntry)
        //console.log(typeof state.currentEditRow.docKey)

        try {
            let result = await api.get(`/warehouse/receiving/po-details/${state.currentEditRow.docEntry}`, {
                params: {
                    docKey: state.currentEditRow.docKey
                }
            });
            //console.log(result)
            console.log(result.detailLines.recordset)
            dispatch(actions.setState({ detailLines: result.detailLines.recordset, modalType: ___.PURCHASE_ORDER }));
        } catch (err) {
            console.error(err);
        }
        dispatch(actions.setState({ loading: false }));
    },
    getIncomingPROSODetails: () => async (dispatch, getState) => {
        console.log('[Incoming.redux.js] getIncomingPROSODetails()')
        const state = getState().warehouse;
        dispatch(actions.setState({ loading: true }));
        //console.log(state.currentEditRow)
        //console.log(state.currentEditRow.docKey)
        //console.log(state.currentEditRow.docEntry)
        //console.log(typeof state.currentEditRow.docKey)

        try {
            // QUERY Warehouse.GetPROFinishedGood
            let result = await api.get(`/warehouse/receiving/pro-so-details/${state.currentEditRow.docEntry}`, {
                params: {
                    docKey: state.currentEditRow.docKey,
                },
            });
            //console.log(result)
            console.log(result.detailLines.recordset)
            dispatch(actions.setState({ detailLines: result.detailLines.recordset, modalType: ___.PRODUCTION_ORDER }));
        } catch (err) {
            console.error(err);
        }
        dispatch(actions.setState({ loading: false }));
    },

    writeProductionOrder: (currentEditRow, popSuccessCB) => async (dispatch, getState) => {
        console.log('[Incoming.redux.js] writeProductionOrder()');
        const state = getState().warehouse;
        //const { currentEditRow } = state;
        console.log(currentEditRow);

        dispatch(actions.setState({ loading: true }));
        try {
            let result = await api.put('/warehouse/write-goods-receipt-pro',
                {
                    BaseDocEntry: currentEditRow.docEntry,
                    BaseDocType: 202, // OR currentEditRow.objType
                    Qty: currentEditRow.orderQty,
                    Comments: currentEditRow.notes,
                    //WhsCode: 'TODO', // backend DEFAULT_WHS_CODE = 'MN';
                });
            console.log(result);
            //console.log(result.data);

            console.log(result.success);
            if (result.success) {
                console.log('success');
                popSuccessCB();
                dispatch(actions.getIncoming());
            }

        } catch (err) {
            console.error((result & result.errorMsg) ? result.errorMsg : 'Could not Write');
            console.error(err);
        }
        dispatch(actions.setState({ loading: false }));
    },

    writePurchaseOrder: (currentEditRow, editDetailsLines, popSuccessCB) => async (dispatch, getState) => {
        console.log('[Incoming.redux.js] writePurchaseOrder()');
        //console.log(currentEditRow);
        //console.log(editDetailsLines);

        const state = getState().warehouse;
        dispatch(actions.setState({ loading: true }));

        let _filtered = editDetailsLines.filter((r) => (r.orderQty && r.orderQty > 0));
        //console.log(_filtered);

        let sendBody = {
            BaseDocEntry: currentEditRow.docEntry,
            BaseDocType: 22, // OR currentEditRow.objType
            CardCode: currentEditRow.cardCode,
            submitRows: _filtered,
            //WhsCode: 'TODO', // backend DEFAULT_WHS_CODE = 'MN';
        };

        // TODO_BLANK_NOTES need to prevent writing comments if they are blank, except when they would be intentionally blank
        if (currentEditRow.notes && currentEditRow.notes !== '') {
            sendBody.Comments = currentEditRow.notes;
        }

        try {
            let result = await api.put('/warehouse/write-grpo',
                sendBody);
            console.log(result);

            //console.log(result.success);
            if (result.success) {
                console.log('success');
                popSuccessCB();
                dispatch(actions.getIncoming());
                //dispatch(actions.setState({ purchaseOrders: result.purchaseOrders.recordset }));
            }

            //console.log(result.errorMsg); // errorMsg: "One of the base documents has already been closed"

        } catch (err) {
            console.error((result & result.errorMsg) ? result.errorMsg : 'Could not Write');
            console.error(err);
        }
        dispatch(actions.setState({ loading: false }));
    },
};
