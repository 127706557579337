import React, { lazy } from 'react';
import { registerReduxStore } from 'core/store';
import { registerRoutes } from 'core/routes';
import { registerNavigation } from 'core/navigation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxOpen } from '@fortawesome/free-solid-svg-icons';
import { actions as warehouseActions } from './Incoming/Incoming.redux';
import { reducers as warehouseReducers } from './Incoming/Incoming.redux';

import { actions as outgoingActions } from './Outgoing/Outgoing.redux';
import { reducers as outgoingReducers } from './Outgoing/Outgoing.redux';

import { actions as productionActions } from './Production/Production.redux';
import { reducers as productionReducers } from './Production/Production.redux';

const Incoming = lazy(() => import('./Incoming/Incoming'));
const Production = lazy(() => import('./Production/Production'));
const Outgoing = lazy(() => import('./Outgoing/Outgoing'));


// IMPORTANT  new redux stores must be added, and they cannot be combined under the same name
registerReduxStore('warehouse', warehouseReducers, warehouseActions);
registerReduxStore('warehouseOut', outgoingReducers, outgoingActions);
registerReduxStore('warehouseProd', productionReducers, productionActions);

registerRoutes([
    {
        path: '/warehouse/receiving',
        exact: true,
        render: <Incoming />,
        title: 'Warehouse: Receiving',
        private: true,
        permissions: [],
    },
    {
        path: '/warehouse/production',
        exact: true,
        render: <Production />,
        title: 'Warehouse: Production',
        private: true,
        permissions: [],
    },
    {
        path: '/warehouse/shipping',
        exact: true,
        render: <Outgoing />,
        title: 'Warehouse: Shipping',
        private: true,
        permissions: [],
    },
]);

registerNavigation({
    title: 'Warehouse',
    permissions: [],
    single: false,
    top: true,
    home: true,
    icon: <FontAwesomeIcon icon={faBoxOpen} />,
    children: [
        {
            title: 'Receiving',
            url: '/warehouse/receiving',
            permissions: [],
        },
        {
            title: 'Production',
            url: '/warehouse/production',
            permissions: [],
        },
        {
            title: 'Shipping',
            url: '/warehouse/shipping',
            permissions: [],
        },
    ],
});

