import { message, notification } from 'antd';

import api from 'api';
import { batch } from 'react-redux';
import { actions as layoutActions } from 'core/Layout/Layout.redux';

let typesRaw = {
	SET_STATE: 'SET_STATE',
	RESET_ALL: 'RESET_ALL',
	SET_SKU_SELECTION: 'SET_SKU_SELECTION',
	SET_MULTI_SKU_SELECTION: 'SET_MULTI_SKU_SELECTION',
	SET_ALL_SKU_SELECTION: 'SET_ALL_SKU_SELECTION',
	SET_META_PROPERTY: 'SET_META_PROPERTY',
	SET_SKU_EDIT: 'SET_SKU_EDIT',
	UPDATE_SKU: 'UPDATE_SKU',
};

export const types = Object.keys(typesRaw).reduce((all, key) => ({
	...all,
	[ key ]: `PRODUCT_CONFIGURATOR/MODEL_SKU_MANAGER/${typesRaw[ key ]}`,
}), {});



const initialState = {
	loading: false,
	saveSuccess: false,
	saving: false,
	modelSaveProgress: 0,
	errors: [],

	original: {},

	columns: [],
	rows: [],
	skus: {},

	show_edit_all_modal: false,
	show_edit_multi_modal: false,
	multi_edit_property_name: null,
	multi_edit_property_value: null,


	model_code: '',
	model_id: null,
	model_label: '',
	primary_attachment_id: null,
	primary_attachment_filename: null,
	category_id: null,
	category_label: '',
	sales_property:null,
	sales_property_list:[],


	padding: {
		model_number: { pattern: '0', length: 4 },
		property_1: { pattern: '0', length: 0 },
		property_2: { pattern: '0', length: 0 },
	},
	separators: {
		modelbase_modelnum: '',
		modelcode_prop1: '-',
		prop1_prop2: '-',
		prop1_inner: '-',
		prop2_inner: '-',
	},
	disallowedCharacters: [ '*', '{', '}', '%', '!', '^', '=', '<', '>', '?', '|' ],
	active: true,
	model_properties_sku1: [],
	model_properties_sku2: [],
	model_properties_other: [],

	option_set_values: {},

	labelEdit: false,
	skuEdit: null, // {}
	propertyEdit: null, // {}

	attachments: [],
	attachment_preview_url: null,
};




export const reducers = (state = initialState, action) => {
	switch(action.type) {
		case types.SET_STATE: {
			return { ...state, ...action.data };
		}



		case types.RESET_ALL: {
			return { ...initialState };
		}



		case types.SET_SKU_SELECTION: {
			return {
				...state,
				skus: {
					...state.skus,
					[ action.sku ]: {
						...state.skus[ action.sku ],
						selected: action.value,
					},
				},
			};
		}



		case types.SET_MULTI_SKU_SELECTION: {
			return {
				...state,
				skus: Object.entries(state.skus).reduce((all, [ sku, data ]) => {
					let skuData = data;

					if(skuData[ action.propertyName ] === action.propertyValue) {
						skuData = { ...data, selected: action.checked };
					}
					all[ sku ] = skuData;
					return all;
				}, {}),
			};
		}



		case types.SET_ALL_SKU_SELECTION: {
			return {
				...state,
				skus: Object.entries(state.skus).reduce((all, [ sku, data ]) => {
					all[ sku ] = { ...data, selected: action.checked };
					return all;
				}, {}),
			};
		}



		case types.SET_META_PROPERTY: {
			return {
				...state,
				model_properties_other: state.model_properties_other.map(property => {
					return property.property_id === action.property_id
						   ? { ...property, value: action.value }
						   : property;
				}),
				skus: Object.entries(state.skus).reduce((all, [ sku, data ]) => {
					all[ sku ] = {
						...data,
						properties: data.properties.map(property => {
							return property.property_id === action.property_id
								   ? { ...property, value: action.value }
								   : property;
						}),
					};
					return all;
				}, {}),
			};
		}



		case types.SET_SKU_EDIT: {
			return {
				...state,
				skuEdit: { ...state.skus[ action.sku ] },
			};
		}



		case types.UPDATE_SKU: {
			return {
				...state,
				skus: {
					...state.skus,
					[ action.sku ]: {
						...state.skus[ action.sku ],
						...action.data,
					},
				},
			};
		}



		default: {
			return state;
		}
	}
};

export default reducers;



export const actions = {
	setState: (data) => ({ type: types.SET_STATE, data }),



	reset_all: (data) => ({ type: types.RESET_ALL, data }),



	get_model: (model_id) => async (dispatch, getState) => {
		dispatch(actions.setLoading(true));

		try {
			let result = await api.get('/configurator/models/sku-grid/' + model_id);
			dispatch(actions.setState({
				columns: result.columns,
				rows: result.rows,
				skus: result.skus,
				original: result.model,
				model_code: result.model.model_code,
				model_id: result.model.model_id,
				model_label: result.model.model_label,
				separators: result.model.modeldef_separator_config,
				padding: result.model.modeldef_padding_config,
				model_properties_sku1: result.model.model_properties_sku1,
				model_properties_sku2: result.model.model_properties_sku2,
				model_properties_other: result.model.model_properties_other,
				option_set_values: result.option_set_values,
				primary_attachment_id: result.model.primary_attachment_id,
				primary_attachment_filename: result.model.primary_attachment_filename,
				category_id: result.model.category_id,
				category_label: result.model.category_label,
				sales_property:result.model.sales_property,
				active:result.model.active,
				loading: false,
			}));
			message.success(`Loaded model ${result.model.model_code}`);
			dispatch(layoutActions.setTitle(`Configurator: SKU Manager for ${result.model.model_code}`));
			dispatch(actions.getAttachments(result.model.model_code));
		} catch(err) {

			dispatch(actions.setLoading(false));
		}
	},



	setSKUSelection: (sku, value) => ({ type: types.SET_SKU_SELECTION, sku, value }),



	setAllSKUSelection: (checked) => ({ type: types.SET_ALL_SKU_SELECTION, checked }),



	setMultiSKUSelection: (propertyName, propertyValue, checked) => ({
		type: types.SET_MULTI_SKU_SELECTION,
		propertyName,
		propertyValue,
		checked,
	}),



	setMetaProperty: (property_id, value) => ({ type: types.SET_META_PROPERTY, property_id, value }),



	setSKUEdit: (sku) => ({ type: types.SET_SKU_EDIT, sku }),



	updateSKU: (sku, data) => ({ type: types.UPDATE_SKU, sku, data }),



	update_label: (newLabel) => (dispatch, getState) => {
		let state    = getState().configurator_sku_manager,
			skus     = state.skus,
			label    = state.model_label,
			skuArray = Object.keys(skus).map(sku => skus[ sku ]);



		if(newLabel === label) {
			return true;
		}

		let update = {
			model_label: newLabel,
		};

		skuArray = skuArray.map(sku => ({ ...sku, label: `${newLabel} ${sku.short_label}` }));

		// Return SKUs to the proper format
		update.skus = skuArray.reduce((all, current) => {
			all[ current.code ] = current;
			return all;
		}, {});

		// ok thanks
		dispatch(actions.setState(update));
	},



	update_all_skus: (data) => (dispatch, getState) => {
		let state      = getState().configurator_sku_manager,
			errors     = state.errors,
			skus       = state.skus,
			label      = state.model_label,
			properties = state.model_properties_other,
			skuArray   = Object.keys(skus).map(sku => skus[ sku ]);

		let update = {};
		dispatch(actions.setState({ errors: [] }));


		// Update the label
		if(label !== data.label) {
			update.model_label = data.label;
			// Update labels on each SKU
			skuArray = skuArray.map(sku => ({ ...sku, label: `${data.label} ${sku.short_label}` }));
		}

		// Update SKU options
		if(data.enabled !== null || data.sales !== null || data.purchasing !== null || data.inventory !== null) {
			skuArray = skuArray.map(sku => ({
				...sku,
				enabled: data.enabled === null
						 ? sku.enabled
						 : data.enabled,
				sales: data.sales === null
						 ? sku.sales
						 : data.sales,
				purchasing: data.purchasing === null
						 ? sku.purchasing
						 : data.purchasing,
				inventory: data.inventory === null
						 ? sku.inventory
						 : data.inventory,
			}));
		}

		// Update metaproperty values
		if(properties !== data.properties) {
			update.model_properties_other = [ ...properties ];

			// We need to be able to get these by ID
			let propertiesByID = data.properties.reduce((all, current) => {
				all[ current.property_id ] = current;
				return all;
			}, {});

			// Update values each SKU
			skuArray = skuArray.map(sku => ({
				...sku,
				properties: sku.properties.map(property => {
					if(propertiesByID[ property.property_id ] === undefined) {
						return property;
					} else {
						return { ...property, value: propertiesByID[ property.property_id ].value };
					}
				}),
			}));
		}

		// Populate costs if set
		if(data.costs.length) {
			skuArray = skuArray.map(sku => ({ ...sku, costs: data.costs }));
		}

		// Populate prices if set
		if(data.prices.length) {
			skuArray = skuArray.map(sku => ({ ...sku, prices: data.prices }));
		}

		// Populate BOM if set
		if(data.bom_items.length) {
			skuArray = skuArray.map(sku => {
				return {
					...sku,
					bom_items: data.bom_items.map(item => {
						if(item.type === 'model') {
							if(item.matches[ sku.short_label ] !== undefined) {
								let { matches, ...newItem } = item;
								item = {
									...newItem,
									type: 'sku',
									code: matches[ sku.short_label ],
								};
							} else {
								errors.push(`One or more SKU properties for ${sku.code} on model ${item.code} are missing or inactive.`);
							}
						}

						return item;
					}),
				};
			});
		}

		if (errors.length) {
			dispatch(actions.setState({ errors: errors }));
			return;
		}

		// Return SKUs to the proper format
		update.skus = skuArray.reduce((all, current) => {
			all[ current.code ] = {
				...current,
				use_bom: data.use_bom,
			};
			return all;
		}, {});

		// ok thanks
		dispatch(actions.setState(update));
		dispatch(actions.setState({ saveSuccess: true, show_edit_all_modal: false }));
	},



	update_skus_by_property: (propertyName, propertyValue, data) => (dispatch, getState) => {
		let state      = getState().configurator_sku_manager,
			errors     = state.errors,
			skus       = state.skus,
			label      = state.model_label,
			properties = state.model_properties_other,
			skuArray   = Object.keys(skus).map(sku => skus[ sku ]);

		let update = {};
		dispatch(actions.setState({ errors: [] }));

		// Update the label
		if(label !== data.label) {
			update.model_label = data.label;
			// Update labels on each SKU
			skuArray = skuArray.map(sku => {
				if(sku[ propertyName ] !== propertyValue) {
					return sku;
				}

				return { ...sku, label: `${data.label} ${sku.short_label}` };
			});
		}


		// Update SKU options
		if(data.enabled !== null || data.sales !== null || data.purchasing !== null || data.inventory !== null) {
			skuArray = skuArray.map(sku => {
				if(sku[ propertyName ] !== propertyValue) {
					return sku;
				}

				return {
					...sku,
					enabled: data.enabled === null
							 ? sku.enabled
							 : data.enabled,
					sales: data.sales === null
						   ? sku.sales
						   : data.sales,
					purchasing: data.purchasing === null
						? sku.purchasing
						: data.purchasing,
					inventory: data.inventory === null
							   ? sku.inventory
							   : data.inventory,
				};
			});
		}


		// Update metaproperty values
		if(properties !== data.properties) {
			update.model_properties_other = [ ...properties ];

			// We need to be able to get these by ID
			let propertiesByID = data.properties.reduce((all, current) => {
				all[ current.property_id ] = current;
				return all;
			}, {});

			// Update values each SKU
			skuArray = skuArray.map(sku => {
				if(sku[ propertyName ] !== propertyValue) {
					return sku;
				}

				return {
					...sku,
					properties: sku.properties.map(property => {
						if(propertiesByID[ property.property_id ] === undefined) {
							return property;
						} else {
							return { ...property, value: propertiesByID[ property.property_id ].value };
						}
					}),
				};
			});
		}

		// Populate BOM if set
		if(data.bom_items.length) {
			skuArray = skuArray.map(sku => {
				if(sku[ propertyName ] !== propertyValue) {
					return sku;
				}

				return {
					...sku,
					bom_items: data.bom_items.map(item => {
						if(item.type === 'model') {
							if(item.matches[ sku.short_label ] !== undefined) {
								let { matches, ...newItem } = item;
								item = {
									...newItem,
									type: 'sku',
									code: matches[ sku.short_label ],
								};
							} else {
								errors.push(`One or more SKU properties for ${sku.code} on model ${item.code} are missing or inactive.`);
							}
						}

						return item;
					}),
				};
			});
		}

		if (errors.length) {
			dispatch(actions.setState({ errors: errors }));
			return;
		}

		// Populate costs if set
		if(data.costs.length) {
			skuArray = skuArray.map(sku => {
				if(sku[ propertyName ] !== propertyValue) {
					return sku;
				}
				return { ...sku, costs: data.costs };
			});
		}

		// Populate prices if set
		if(data.prices.length) {
			skuArray = skuArray.map(sku => {
				if(sku[ propertyName ] !== propertyValue) {
					return sku;
				}
				return { ...sku, prices: data.prices };
			});
		}

		// Return SKUs to the proper format
		update.skus = skuArray.reduce((all, current) => {
			all[ current.code ] = {
				...current,
				use_bom: data.use_bom,
			};
			return all;
		}, {});

		// ok thanks
		dispatch(actions.setState({ saveSuccess: true }));
		dispatch(actions.setState(update));
	},



	setLoading: (loading = false) => ({ type: types.SET_STATE, data: { loading } }),

	setSaving: (saving = false) => ({ type: types.SET_STATE, data: { saving } }),



	getAttachments: (model_code) => async (dispatch) => {
		dispatch(actions.setLoading(true));

		try {
			let response = await api.get(`/configurator/attachments/model/${model_code}`);

			batch(() => {
				dispatch(actions.setState({
					attachments: response.attachments,
				}));
				dispatch(actions.setLoading(false));
			});
		} catch(err) {
			console.error(err);
			dispatch(actions.setLoading(false));
		}
	},



	set_primary_attachment: (model_id, attachment_id) => async (dispatch) => {
		dispatch(actions.setLoading(true));

		try {
			let response = await api.put('/configurator/models/primary-attachment', {
				model_id,
				attachment_id,
			});

			dispatch(actions.setState({
				loading: false,
				primary_attachment_id: response.primary_attachment_id,
				primary_attachment_filename: response.primary_attachment_filename,
			}));
		} catch(err) {
			console.error(err);
			dispatch(actions.setLoading(false));
		}
	},


	delete_attachment: (attachment_id) => async (dispatch, getState) => {
		dispatch(actions.setLoading(true));
		let state       = getState().configurator_sku_manager,
			primaryID   = state.primary_attachment_id,
			model_id    = state.model_id,
			attachments = state.attachments;

		try {
			// Delete it
			await api.delete(`/configurator/attachments/item/${model_id}/${attachment_id}`);

			// Remove what we deleted from the current attachments list
			let update = {
				loading: false,
				attachments: attachments.filter(attachment => attachment.attachment_id !== attachment_id),
			};

			// Check if we deleted the primary, set it back to null if so
			if(primaryID === attachment_id) {
				update.primary_attachment_id = null;
				update.primary_attachment_filename = null;
			}

			dispatch(actions.setState(update));
		} catch(err) {
			console.error(err);
			dispatch(actions.setLoading(false));
		}
	},

	getSalesPropList: () => async (dispatch) => {

		try {
			let result = await api.get('/sap/sales-properties');
			dispatch(actions.setState({ sales_property_list: result.sales_properties.map(prop => prop.sales_property_name )}));
		} catch(e) {
			console.error(e);
		}
	},

	save_model: (sync_all) => async (dispatch, getState) => {
		dispatch(actions.setState({
			saving: true,
			modelSaveProgress: 0,
		}));

		let state   = getState().configurator_sku_manager,
			payload = {
				model_id: state.model_id,
				model_label: state.model_label,
				model_properties_other: state.model_properties_other,
				category_id: state.category_id,
				sales_property:state.sales_property,
				sync_all: sync_all,
				skus: Object.keys(state.skus)
					.map(sku => state.skus[ sku ])
					.filter(sku => (sku.selected === true || Boolean(sku.sku_id)))
					.reduce((all, current) => {
						all[ current.code ] = current;
						return all;
					}, {}),
				active: state.active,
			};

		try {
			await api.put('/configurator/models/save', {
				model: payload,
			});


			dispatch(actions.setState({ modelSaveProgress: 100 }));
			dispatch(actions.get_model(state.model_id));
		} catch(err) {
			// do something?
			console.error(err);
			dispatch(actions.setState({ loading: false }));
		}

		dispatch(actions.setState({ saving: false }));
	},


};




/*****************************
 *     REDUCER FUNCTIONS     *
 ****************************/
