import { isNull, isUndefined } from 'lodash';

import api from 'api';
import { actions as layoutActions } from 'core/Layout/Layout.redux';
import { message } from 'antd';

const defaultLine = {
	row_index: 0,
	sap_line_id: null,
	quantity: 1,
	sku: '',
	line_sku: '',
	unit_cost: 0,
	unit_price: 0,
};


const initialState = {
	loading: false,
	saving: false,
	saveButtonDisabled: true,
	saveSuccess: false,
	sapNumber:null,

	bp_list: [],
    shippingMethods: [],
    employees: [],
	sales_persons: [],
    states: {},
	countries: [],
	defaultCountry: 'US',
	selected_sap_number: null,

	orderHeader: {
		sap_id: null, // ??
		SONum: null, //  SO# that ties POs to SOs
		vendor_code: '',
		vendor_name: '',
		ship_date: '',
		event_date: '',
		has_event_date: '',
		project_name: '',
		document_owner_id: null,
		sales_person: -1,
		production_notes: '',
		shipping_method: '',
		address_street: '',
		address_block: '',
        address_city: '',
        address_state: '',
		address_country: '',
        address_zip: '',
		document_total: null,
		address_attn: '',
		customer_name: '', //comes from PO's generated from SO's
		customer_name_udf: '', //UDF user sets if theres no customer_name
	},

	orderLines: [ {
		...defaultLine,
	} ],

	deleted_lines: [],

	save_response: {
		item_sync_messages: [],
		errors: [],
	},
};


export const types = {
    SET_STATE: 'PURCHASE_ORDER_ENTRY/SET_STATE',
	RESET_STATE: 'PURCHASE_ORDER_ENTRY/RESET_STATE',
	SET_ORDER_HEADER: 'PURCHASE_ORDER_ENTRY/SET_ORDER_HEADER',
	SET_LINE_DATA: 'PURCHASE_ORDER_ENTRY/SET_LINE_DATA',
	SET_LINE_AMOUNT: 'PURCHASE_ORDER_ENTRY/SET_LINE_AMOUNT',
	ADD_LINE: 'PURCHASE_ORDER_ENTRY/ADD_LINE',
	ADD_LINE_MULTI: 'PURCHASE_ORDER_ENTRY/ADD_LINE_MULTI',
	DELETE_LINE: 'PURCHASE_ORDER_ENTRY/DELETE_LINE',
	ENABLE_SAVE_BUTTON: 'ENABLE_SAVE_BUTTON',
	DISABLE_SAVE_BUTTON : 'DISABLE_SAVE_BUTTON',
};


export const reducers = (state = initialState, action) => {

    switch(action.type) {
        case types.SET_STATE:
            return { ...state, ...action.data };

		case types.RESET_STATE:
			{
				return { ...initialState };
			}

		case types.SET_ORDER_HEADER:
			return {
				...state,
				orderHeader: {
					...state.orderHeader,
					...action.data,
				},
			};

		case types.SET_LINE_DATA:
			{
				return {
					...state,
					orderLines: [
						...state.orderLines.slice(0, action.index),
						{
							...state.orderLines[action.index],
							...action.data,
						},
						...state.orderLines.slice(action.index + 1),
					],
				};
			}

		case types.SET_LINE_AMOUNT:
			{
				return {
					...state,
					orderLines: [
						...state.orderLines.slice(0, action.index),
						{
							...state.orderLines[action.index],
							...calculateLineAmounts(state.orderLines[action.index], action.property, action.value),
						},
						...state.orderLines.slice(action.index + 1),
					],
				};
			}

		case types.ADD_LINE:
			{
				return {
					...state,
					orderLines: [
						...state.orderLines,
						{
							...defaultLine,
							row_index: state.orderLines.length,
						},
					],
				};
			}

		case types.ADD_LINE_MULTI:
			{
				return {
					...state,
					orderLines: [
						...state.orderLines.slice(0, action.fromIndex),
						...action.lines.map((line, index) => ({
							...defaultLine,
							...line,
						})),
						...state.orderLines.slice(action.fromIndex + 1),
					].map((line, index) => ({...line, row_index: index })),
				};
			}

		case types.DELETE_LINE:
			{
				return {
					...state,
					orderLines: state.orderLines
						.filter((line, index) => index !== action.index)
						.map((line, index) => ({ ...line, row_index: index})),
					deleted_lines: [
						...state.deleted_lines,
						...((state.orderLines[action.index].sap_line_id === null) ?
							[] :
							[{
								sap_line_id: state.orderLines[action.index].sap_line_id,
							}]
						),
					],
				};
			}

		case types.ENABLE_SAVE_BUTTON:
			return {
				...state,
				saveButtonDisabled: true,
			};

		case types.DISABLE_SAVE_BUTTON:
			return {
				...state,
				saveButtonDisabled: false,
			};

        default:
            return state;
    }
};

export const actions = {
	setState: (data) => ({ type: types.SET_STATE, data }),

    resetState: () => ({ type: types.RESET_STATE }),

	set_order_header: (data) => ({ type: types.SET_ORDER_HEADER, data}),

	set_line_data: (index, data) => ({ type: types.SET_LINE_DATA, index, data }),

	add_line: () => ({ type: types.ADD_LINE }),


	add_line_multi: (lines = [], fromIndex) => async(dispatch) => {
		dispatch({ type: types.ADD_LINE_MULTI, lines, fromIndex });
	},

	delete_line: (index) => ({ type: types.DELETE_LINE, index }),

    load_required_data: () => async(dispatch) => {
		dispatch(actions.setState({ loading: true }));

		try {
			let [locations, shippingMethods, employees, salesPersons]  = await Promise.all([
				api.get('/bp/locations'),
				api.get('/sap/shipping-methods'),
				api.get('/sap/employees'),
				api.get('/sap/sales-persons'),
				// api.get('/bp/list', {
				// 	params: {
				// 		search: '',
				// 		bp_type: 'C',
				// 	},
				// }),
			]);

			let { states, countries } = locations;
			dispatch(actions.setState({
				states,
				countries,
				shippingMethods: shippingMethods.shippingMethods,
				employees: employees.employees,
				sales_persons: salesPersons.sales_persons,
				//bp_list: bps.bps,
			}));
		} catch (err) {
			console.error(err);
		}
	},

	load_order: (docEntry) => async(dispatch) => {

		console.log('[PurchaseOrderEntry.redux] load_order');
		console.assert(docEntry, 'docEntry was null');

		dispatch(actions.setState({loading: true}));

		try {
			let result = await api.get('/purchase_orders/edit', {
				params: {
					doc_entry: docEntry,
				},
			});

			if (result.purchaseOrder.sap_number) {
				dispatch(layoutActions.setTitle(`Purchase Order: SAP #${result.purchaseOrder.sap_number}`));
			}

			console.log('result.purchaseOrder.sap_id');
			console.log(result.purchaseOrder.sap_id);
			console.log('result.purchaseOrder.SONum');
			console.log(result.purchaseOrder.SONum);

			let {
				customer_name,
				order_customer_name,
				address_attn,
				address_block,
				address_city,
				address_state,
				address_country,
				address_street,
				address_zip,
				document_owner_id,
				sales_person,
				event_date,
				ship_date,
				production_notes,
				ship_method,
				vendor_code,
				vendor_name,
				project_name,
				sap_id, // 
				SONum, // 
			} = result.purchaseOrder;
			let updatedOrderLines = [];

        // Loop through the received lines and update the order lines state
        result.lines.forEach((line) => {
			let {
				row_index,
				sap_line_id,
				sku,
				quantity,
				unit_price,
				unit_cost,
			} = line;

			// Create a new line object with the updated data
			let updatedLine = {
				row_index: row_index,
				sap_line_id: sap_line_id,
				quantity: quantity,
				sku: sku,
				line_sku: sku,
				unit_cost: unit_cost,
				unit_price: unit_price,
			};

			// Add the updated line to the array
			updatedOrderLines.push(updatedLine);
		});
		dispatch(actions.setState({ orderLines: updatedOrderLines }));
			dispatch(
				actions.setState({
					orderHeader: {
						sap_id: sap_id,
						vendor_code: vendor_code,
						vendor_name: vendor_name,
						customer_name_udf:  order_customer_name,
						ship_date: ship_date,
						event_date: event_date,
						project_name: project_name,
						document_owner_id: document_owner_id,
						sales_person: sales_person,
						production_notes: production_notes,
						shipping_method: ship_method,
						customer_name: customer_name,
						address_attn: address_attn,
						address_street: address_street,
						address_block: address_block,
						address_city: address_city,
						address_state: address_state,
						address_country: address_country,
						address_zip: address_zip,
						SONum: SONum,
					},
				}),
			);
		} catch (err) {
			console.error(err);
		}

		dispatch(actions.setState({loading: false}));
	},


	create_purchase_order: () => async(dispatch, getState) => {
		const state 	 = getState(),
			orderHeader  = state.purchaseOrderEntry.orderHeader,
			orderLines 	 = state.purchaseOrderEntry.orderLines,
			deletedLines = state.purchaseOrderEntry.deleted_lines;
		try{
			dispatch(actions.setState({saving: true}));

			console.log('orderHeader');
			console.log(orderHeader);

			console.log('orderHeader.sap_id');
			console.log(orderHeader.sap_id);

			console.log('orderHeader.SONum');
			console.log(orderHeader.SONum);


			let result = await api.post('/purchase_orders/new', {
				orderHeader,
				orderLines,
				deletedLines,
			});
			
			dispatch(actions.setState({ 
				saveSuccess: true,
				saving: false,
				orderHeader: {
					sap_id: result.poKey, // is this right?
				},
				deleted_lines: [],
			}));
			
			message.success(`Purchase order ${ result.poKey } is saved!`);
			if(result.response.item_sync_messages){
				message.success(result.response.item_sync_messages);
			}
			
			dispatch(actions.load_order(result.poKey)); // reload the order from server if the save was successful

		} catch(err){
			console.log(err);
			message.error(`There was a problem saving edits to the purchase order: ${err}`);
			dispatch(actions.enable_save_button()); // allow user to try save again
			dispatch(actions.setState({
				saving: false,
				saveSuccess: false,
			}));
		}
	},


	enable_save_button: () => async(dispatch,getState) => {
		const state = getState();
		const requiredFields = [
			'vendor_code',
			'address_street',
			'address_city',
			'address_state',
			'address_zip',
			'ship_date',
		];
		const requiredLinesItems = [
			'sku',
			'quantity',
		];

		const isAllRequiredFieldsFilled = requiredFields.every(field => {
			let fieldValue = state.purchaseOrderEntry.orderHeader[field];
			if (fieldValue === '' || isNull(fieldValue) || isUndefined(fieldValue)) {
				return false;
			}
			return true;
		});

		let isAllRequiredLineFilled = false;
		if(state.purchaseOrderEntry.orderLines.length >= 1){
			for (let i = 0; i < state.purchaseOrderEntry.orderLines.length; i++) {
				let orderLine = state.purchaseOrderEntry.orderLines[i];
				let completeLine = requiredLinesItems.every(field => {
					let lineItemValue = orderLine[field];
					if (lineItemValue === '' || isNull(lineItemValue) || isUndefined(lineItemValue) || lineItemValue <= 0) {
						return false;
					}
					return true;
				});

				if (!completeLine) {
					isAllRequiredLineFilled = false;
					break;
				}
				if (i === state.purchaseOrderEntry.orderLines.length - 1) {
					isAllRequiredLineFilled = true;
				}
			}

		}


		if (isAllRequiredFieldsFilled && isAllRequiredLineFilled ) {
			dispatch(actions.setState({ saveButtonDisabled: false }));
		} else {

			dispatch(actions.setState({ saveButtonDisabled: true }));
		}
	},

	get_order_totals: () => (dispatch, getState) => {
		const state = getState(),
			lines = state.purchaseOrderEntry.orderLines;


		const totals = {
			totalQuantity: 0,
			totalPrice: 0,
			row_index: null, // done below
		};


		for (let i = 0; i < lines.length; i++) {
			const line = lines[i];

			totals.totalQuantity += line.quantity;
			totals.totalPrice += parseFloat(line.unit_price) * line.quantity;
			totals.row_index = i;
		}

		totals.totalPrice = (totals.totalPrice).toFixed(6);
		return totals;
	},

};

function calculateLineAmounts(line, property, value) {
	if (!line) {
		return;
	}

	let result = {
		unit_price: line.unit_price,
		unit_cost: line.unit_cost,
	};

	value = value || 0;

	// unit_cost for an item with BOMs needs to be calculated from the total of the BOM items
	// unit_cost must be calculated first because the unit_price depends on this being set.
	if (property === 'unit_cost' && line.extras?.bom_children?.length) {
		let new_unit_cost = 0;

		for (let i = 0; i < line.extras.bom_children.length; i++) {
			const child = line.extras.bom_children[ i ];
			if (child.unit_cost) {
				new_unit_cost += Number((child.unit_cost * child.quantity).toFixed(6));
			}
		}

		result.unit_cost = new_unit_cost;
	} else {
		result[property] = value;
	}

	// Set the margin if unit price was entered and there's a unit cost
	if (property === 'unit_price' && value > 0) {
		result.margin = Number(((value - line.unit_cost) / value) * 100).toFixed(6);
	}

	// Set the unit price if unit cost was entered and there's a margin
	if (property === 'unit_cost' && line.margin > 0 && value > 0) {
		result.unit_price = Number(value / (1 - (line.margin / 100))).toFixed(6);
	}

	return result;

}
