import { actions as itemSearchModalActions, reducers as itemSearchModalReducers } from 'components/ItemSearchModal/ItemSearchModal.redux';
import { actions as orderActions, reducers as orderReducers } from './OrderEntry.redux';
import { actions as productionActions, reducers as productionReducers } from './Production/Production.redux';
import { actions as purchaseOrderActions, reducers as purchaseOrderReducers } from './Purchasing/PurchaseOrder.redux';
import { actions as purchaseOrderEntryActions, reducers as purchaseOrderEntryReducers } from '../PurchaseOrderEntry/PurchaseOrderEntry.redux';
import React, { lazy } from 'react';

import { faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { registerNavigation } from 'core/navigation';
import { registerReduxStore } from 'core/store';
import { registerRoutes } from 'core/routes';

const OrderEntry = lazy(() =>
		import ('./OrderEntry')),
	OrderSearch = lazy(() =>
		import ('./OrderSearch')),
	PurchaseOrder = lazy(() =>
		import ('./Purchasing/PurchaseOrder')),
	PurchaseOrderEntry = lazy(() =>
		import ('../PurchaseOrderEntry/PurchaseOrderEntry')),
	PurchaseOrderSearch = lazy(() =>
		import ('./Purchasing/PurchaseOrderSearch'));



registerReduxStore('orders', orderReducers, orderActions);
registerReduxStore('production', productionReducers, productionActions);
registerReduxStore('purchasing', purchaseOrderReducers, purchaseOrderActions);
registerReduxStore('purchaseOrderEntry', purchaseOrderEntryReducers, purchaseOrderEntryActions);
registerReduxStore('itemSearchModal', itemSearchModalReducers, itemSearchModalActions);


registerRoutes([{
	path: '/orders/search',
	exact: true,
	render: < OrderSearch />,
	title: 'Sales Orders: Search',
	private: true,
	permissions: [],
},
{
	path: '/orders/purchase-order/search',
	exact: true,
	render: < PurchaseOrderSearch />,
	title: 'Purchase Orders: Search',
	private: true,
	permissions: [],
},
{
	path: '/orders/sales-order',
	exact: true,
	render: < OrderEntry mode = "new" /> ,
	title: 'Sales Orders: New',
	private: true,
	permissions: [],
},
{
	path: '/orders/purchase-order',
	exact: true,
	render: < PurchaseOrderEntry mode = "new" />,
	title: 'Purchase Orders: New',
	private: true,
	permissions: [],
},
{
	path: '/orders/edit/:document_type/:document_id',
	exact: true,
	render: < OrderEntry mode = "edit" />,
	title: 'Sales Orders: Edit',
	private: true,
	permissions: [],
},
{
	path: '/orders/purchase-order/edit/:docEntry',
	exact: true,
	render: < PurchaseOrderEntry mode = "edit" />,
	title: 'Purchase Orders: Edit',
	private: true,
	permissions: [],
},
{
	path: '/orders/purchasing/:orderID',
	exact: true,
	render: < PurchaseOrder />,
	title: 'Purchase Orders',
	private: true,
	permissions: [],
},


]);

registerNavigation({
	title: 'Orders',
	permissions: [],
	single: false,
	top: true,
	home: true,
	icon: < FontAwesomeIcon icon = { faDollarSign }
	/>,
	children: [{
		title: 'Search Sales Orders',
		url: '/orders/search',
		permissions: [],
	},
	{
		title: 'Search Purchase Orders',
		url: '/orders/purchase-order/search',
		permissions: [],
	},
	{
		title: 'New Sales Order',
		url: '/orders/sales-order',
		permissions: [],
	},
	{
		title: 'New Purchase Order',
		url: '/orders/purchase-order',
		permissions: [],
	},
	],
});




console.log('Registered Order Entry Plugin');
