import api from 'api';
import { createRowCache } from '@devexpress/dx-react-grid';

const initialState = {
    allOrders: {rows: [], total_count: 0},
    purchaseOrders: {},
	portal_id: null,
    order_id: null,
    loading: false,
    poLoading: false,
	poLinkLoading: false,
	take: 200,
	skip: 0,
	limit: 100,
	sorting: [],
	filters: [],
	requestedPage: 0,
	forceReload: false,
	logged_in_rep: '',
	sales_person_list:[],
	owner_list:[],
	filterValues: {
		order_date_formatted: null,
		event_date: null,
		has_po: null,
		status: null,
		document_owner_name: [],
		sales_person_name: [],
	},
	lastQuery: '',
};
let lastRequestId =0;

export const types = {
	SET_STATE: 'ALLORDERS/SET_STATE',
	SET_FILTER_VALUES: 'ALLORDERS/SETFILTERVALUES',
	START_LOADING: 'ALLORDERS/START_LOADING',
	CHANGE_FILTERS: 'ALLORDERS/CHANGE_FILTERS',
	SET_PURCHASE_ORDERS: 'ALLORDERS/SET_PURCHASE_ORDERS',
};




export const reducers = (state = initialState, action) => {
	switch(action.type) {
		case types.SET_STATE:
			return { ...state, ...action.data };

		case types.SET_FILTER_VALUES:
			return {
				...state,
				filterValues: {
					...state.filterValues,
					...action.data,
				},
			};

		case types.START_LOADING:
			return {
				...state,
				requestedPage: action.data.requestedPage,
				take: action.data.take,
			};

		case types.CHANGE_FILTERS:
			return {
				...state,
				forceReload: true,
				requestedSkip: 0,
				allOrders: [],
				filters: action.data.filters,
			};

		case types.SET_PURCHASE_ORDERS:
			return {
				...state,
				purchaseOrders: {
					...state.purchaseOrders,
					[action.data.portal_id]: action.data.POs,
				},
			};

		default:
			return state;
	}
};




export const actions = {
	setState: (data) => ({ type: types.SET_STATE, data }),

	setFilterValues: (data) => ({ type: types.SET_FILTER_VALUES, data }),

	startLoading: (data) => ({type: types.START_LOADING, data}),

	setPurchaseOrders: (data) => ({type: types.SET_PURCHASE_ORDERS, data }),

	loadData: (debouncedSearchStrings, searchOptions) => async (dispatch, getState) =>{
		const state         = getState(),
			requestedPage 	= state.allorders.requestedPage,
			loading       	= state.allorders.loading,
			take         	= state.allorders.take,
			limit         	= state.allorders.limit,
			lastQuery		= state.allorders.lastQuery,
			requestId		= ++lastRequestId,
			cache			= createRowCache(limit);

		dispatch(actions.setState({ loading: true }));

		try {

			let query = `/orders/search?type=dashboard&page=${requestedPage}&limit=${take}&customer=${debouncedSearchStrings.customer}&docnum=${debouncedSearchStrings.docnum}&owner=${debouncedSearchStrings.owner}&project=${debouncedSearchStrings.project}&salesperson=${debouncedSearchStrings.salesperson}&status=${searchOptions.status.charAt(0)}`;
			if (query !== lastQuery && !loading) {
				// Check if data exists in cache
				const cached = cache.getRows(requestedPage, take);

				if (cached.length > 0) {
					// If data is cached, use it directly
					dispatch(
						actions.setState({
							allOrders: { rows: cached, total_count: cache.totalCount },
							lastQuery: query,
							skip: requestedPage,
						}),
					);
				} else {

					const result = await api.get(query);
					// Update cache with fetched data
					if(requestId === lastRequestId){
						cache.setRows(requestedPage, result.orders);
						dispatch(actions.setState({
							allOrders: { rows: result.orders, total_count: result.total_rows },
							lastQuery: query,
							skip: requestedPage,
					}));
				}
				}
			}
		} catch (err){
			console.error(err);
		}
		dispatch(actions.setState({ loading: false }));
	},

    getPurchaseOrders: (portal_id) => async (dispatch, getState) => {
		const state = getState(),
			purchaseOrders = state.allorders.purchaseOrders;

		if (!portal_id) {
			return;
		}

		if (purchaseOrders[portal_id]) {
			return;
		}

		dispatch(actions.setState({poLoading: true}));

		try {
			let result = await api.get(`/purchase_orders/sales_order_pos/${portal_id}`);

            // dispatch(actions.setState({ purchaseOrders[portal_id]: result.documents }));
			dispatch(actions.setPurchaseOrders({ portal_id, POs: result.documents }));
		} catch(err) {
			console.error(err);
		}
		dispatch(actions.setState({poLoading: false}));
	},

	getLoggedInRep: () => async (dispatch) => {

		try {
			let result = await api.get('/user/name');
			dispatch(actions.setState({ logged_in_rep: result.username }));
			dispatch(actions.setFilterValues({ sales_person_name: [ result.username ] }));
		} catch(e) {
			console.error(e);
		}
	},

	getSalesRepList: () => async (dispatch) => {

		try {
			let result = await api.get('/sap/sales-persons');
			dispatch(actions.setState({ sales_person_list: result.sales_persons.map(person => person.sales_person_name )}));
		} catch(e) {
			console.error(e);
		}
	},

	getOwnerList: () => async (dispatch) => {

		try {
			let result = await api.get('/sap/employees');
			dispatch(actions.setState({ owner_list: result.employees.map(person => person.employee_name )}));
		} catch(e) {
			console.error(e);
		}
	},
};
