import React, { lazy } from 'react';
import { registerReduxStore } from 'core/store';
import { registerRoutes } from 'core/routes';
import { registerNavigation } from 'core/navigation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDashboard } from '@fortawesome/free-solid-svg-icons';
import { actions as allOrdersActions } from './AllOrders/AllOrders.redux';
import { reducers as allOrdersReducers } from './AllOrders/AllOrders.redux';


const AllOrders = lazy(() => import('./AllOrders/AllOrders'));

registerReduxStore('allorders', allOrdersReducers, allOrdersActions);

registerRoutes([
	{
		path: '/dashboards/all-orders',
		exact: true,
		render: <AllOrders />,
		title: 'Dashboard: All Orders',
		private: true,
		permissions: [],
	},
]);

registerNavigation({
	title: 'Dashboards',
	permissions: [],
	single: false,
	top: true,
	home: true,
	icon: <FontAwesomeIcon icon={faDashboard} />,
	children: [
		{
			title: 'All Orders',
			url: '/dashboards/all-orders',
			permissions: [],
		},
	],
});

