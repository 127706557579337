let ___ = {
    NOT_AVAILABLE: 'N/A',
    OPEN: 'O',
    CLOSED: 'C',
    TRUE: 'TRUE',
    FALSE: 'FALSE',
    PENDING: 'Pending',
    PARTIAL: 'Partial',
    COMPLETE: 'Complete',
    PURCHASE_ORDER: 'PO',
    PRODUCTION_ORDER: 'PRO',
    PRODUCTION_BACKFLUSH: 'B',
    PRODUCTION_MANUAL: 'M',
    PO_OPEN: 'O',
    PO_CLOSED: 'C',
    PRO_OPEN: 'R',
    PRO_RELEASED: 'R',
    PRO_PLANNED: 'P',
    PRO_CLOSED: 'L',
    PRO_CANCELED: 'C',
    SALES_ORDER_OPEN: 'O',
    SALES_ORDER_CLOSED: 'C',
    QC_ISSUE: 'Issue',
    QC_READY: 'Ready to Ship',
    QC_PENDING: 'Pending',
    DROP_SHIP: 'DropShip',
    BB: 'BB',
};

export default ___;
