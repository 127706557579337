import _, { isNull } from 'lodash';

import api from 'api';
import base64toPDF from 'lib/base64ToPDF';
import { message } from 'antd';

let typesRaw = {
	SET_STATE: 'SET_STATE',
	RESET_ALL: 'RESET_ALL',
	SET_DOCUMENT_DATA: 'SET_DOCUMENT_DATA',
	SET_LINE_DATA: 'SET_LINE_DATA',
	ADD_SHIPPING_ADDRESS: 'ADD_SHIPPING_ADDRESS',
};


export const types = Object.keys(typesRaw).reduce((all, key) => ({
	...all,
	[key]: `PURCHASING/${typesRaw[ key ]}`,
}), {});


const initialState = {
	loading: false,
	saving: false,
	showHelperMessage: false,
	dataFromSO: null,
	shippingMethods: [],
	states: [],
	countries: [],
	
	suggestedDocs: [],
	documents: [],

	docEntry: null, //sapID
	order_id: null, //orderID
	lines: [],
	bp_name: null,
	header: {},
	
};


export const reducers = (state = initialState, action) => {

	switch (action.type) {
		case types.SET_STATE:
		{
			return {...state, ...action.data };
		}


		case types.RESET_ALL:
		{
			return {...initialState };
		}


		// document (header) data can be modified on either suggested or existing docs
		case types.SET_DOCUMENT_DATA:
		{
			return {
				...state,
				[action.documentsType]: [
					...state[action.documentsType].slice(0, action.documentIndex),
					{
						...state[action.documentsType][action.documentIndex],
						...action.data,
					},
					...state[action.documentsType].slice(action.documentIndex + 1),
				],
			};
		}

		// line data can only be modified on a suggested document, not one that has been saved
		case types.SET_LINE_DATA:
		{
			return {
				...state,
				[action.documentsType]: [
					...state[action.documentsType].slice(0, action.documentIndex),
					{
						...state[action.documentsType][action.documentIndex],
						...action.data,
					},
					...state[action.documentsType].slice(action.documentIndex + 1),
				],
			};
		}


		default:
			return state;

	}
};

export default reducers;


export const actions = {
	setState: (data) => ({ type: types.SET_STATE, data }),


	reset_all: () => ({ type: types.RESET_ALL }),


	set_document_data: (documentIndex, documentsType, data) => ({ type: types.SET_DOCUMENT_DATA, documentIndex, documentsType, data}),


	set_document_line_data: (documentIndex, documentsType, lineIndex, data) => ({ type: types.SET_LINE_DATA, documentIndex, documentsType, lineIndex, data }),


	get_purchase_orders: (orderID) => async (dispatch, getState) => {
		const state      = getState(),
			  dataFromSO = state.purchasing.dataFromSO;

		dispatch(actions.setState({ loading: true }));
		dispatch(actions.setState({ showHelperMessage: false }));

		try {
			const res = await api.get(`/orders/sap/production/${orderID}`)
			let prodDocuments = res.production.documents;

			// monkey around to set a default address (ship from SO)
			for (let i=0; i < prodDocuments.length; i++) {
				const doc = prodDocuments[i]
				if (isNull(doc.shipping_address )) {
					doc.shipping_address = dataFromSO.shipping_address
				}
			}
	
			dispatch(actions.setState({ documents: prodDocuments }));
		} catch (err) {
			console.error(err);
		}
		dispatch(actions.setState({ loading: false }));
	},


	get_data_from_sales_order: (orderID) => async (dispatch) => {
		dispatch(actions.setState({ loading: true }));

		try {
			const productionData = await api.get(`orders/production/so-production-data/${orderID}`);
			dispatch(actions.setState({ dataFromSO: productionData }));
		} catch(err){
			console.error(err);
		}
		dispatch(actions.setState({ loading: false }));
	},


	get_shipping_methods: () => async (dispatch) => {
		dispatch(actions.setState({ loading: true }));

		api.get('/sap/shipping-methods').then(res => {
			let shippingMethods = res.shippingMethods;

			dispatch(actions.setState({ shippingMethods }));
			dispatch(actions.setState({ loading: false }));
		}).catch(err => {
			console.error(err);
			dispatch(actions.setState({ loading: false }));
		});
	},

	get_warehouse_options: () => async (dispatch) => {
		//console.log('[PurchaseOrder.redux] get_warehouse_options');
		dispatch(actions.setState({ loading: true }));
		
		api.get('/warehouse/get-warehouse-options-po/').then(res => {
			let warehouseOptions = res.options;
			
			dispatch(actions.setState({ warehouseOptions }));
			dispatch(actions.setState({ loading: false }));
		}).catch(err => {
			console.error(err);
			dispatch(actions.setState({ loading: false }));
		});
		
	},
	
	// get_warehouse_options_pro: () => async (dispatch) => {
	// 	dispatch(actions.setState({ loading: true }));
		
	// 	api.get('/warehouse/get-warehouse-options-pro/').then(res => {
	// 		let warehouseOptions2 = res.options;
			
	// 		dispatch(actions.setState({ warehouseOptions2 }));
	// 		dispatch(actions.setState({ loading: false }));
	// 	}).catch(err => {
	// 		console.error(err);
	// 		dispatch(actions.setState({ loading: false }));
	// 	});
		
	// },

	// get_warehouse_options_bp: () => async (dispatch) => {
	// 	dispatch(actions.setState({ loading: true }));
		
	// 	api.get('/warehouse/get-warehouse-options-bp/').then(res => {
	// 		let warehouseOptions3 = res.options;
			
	// 		dispatch(actions.setState({ warehouseOptions3 }));
	// 		dispatch(actions.setState({ loading: false }));
	// 	}).catch(err => {
	// 		console.error(err);
	// 		dispatch(actions.setState({ loading: false }));
	// 	});
		
	// },

	get_address_states: () => async (dispatch) => {
		dispatch(actions.setState({ loading: true }));
		try {
			let results = await api.get('/bp/locations');

			if (results) {
				dispatch(actions.setState({ states: results.states }));
				dispatch(actions.setState({ countries: results.countries }));
			}
		} catch(err) {
			console.error(err);
		}
		dispatch(actions.setState({ loading: false }));
	},


	generate_suggested_orders: (orderID) => async (dispatch, getState) => {
		const state        = getState(),
			  existingDocs = state.purchasing.documents;

		dispatch(actions.setState({ loading: true }));

		let existingVendorCodes = [];
		existingDocs.forEach(doc => existingVendorCodes.push(doc.vendor_code));

		try {
			let results = await api.post('/orders/production-docs', {
				order_id: orderID,
				existingVendorCodes: existingVendorCodes,
			});

			if (!results.documents.length) {
				dispatch(actions.setState({ showHelperMessage: true }));
			}

			dispatch(actions.setState({ suggestedDocs: results.documents }));
		} catch(err) {
			console.error(err);
		}
		dispatch(actions.setState({ loading: false }));
	},


	// update an individual purchase order when they edit the header on an existing document
	update_purchase_order: (orderID, documentIndex) => async (dispatch, getState) => {
		console.log('[PurchaseOrder.redux] update_purchase_order');
		const state    = getState(),
			  document = state.purchasing.documents[documentIndex];

		dispatch(actions.setState({ saving: true }));

		//console.log('document');
		//console.log(document);

		try {
			await api.post('/orders/purchase-order/update', {
				document,
				order_id: orderID,
			});

			message.success('Successfully updated purchase order');

		} catch(err) {
			console.error(err);
			message.error(`There was a problem updating the order: ${err}`);
		}
		dispatch(actions.setState({ saving: false }));
	},


	//saves suggested POs after generating them
	save_purchase_orders: (orderID) => async (dispatch, getState) => {
		console.log('[PurchaseOrder.redux] save_purchase_orders');
		const state         = getState(),
			  suggestedDocs = state.purchasing.suggestedDocs;

			  //console.log(suggestedDocs);

		dispatch(actions.setState({ saving: true }));

		try {
			let response = await api.post('/orders/purchase-order/suggested', {
				POsToCreate: suggestedDocs,
				order_id: orderID,
			});

			let result = await api.get(`/orders/sap/production/${orderID}`);
			let prodDocuments = result.production.documents;

			dispatch(actions.setState({ documents: prodDocuments }));

			if (response.errors.length) {
				message.error(response.errors.map(error => error ));
			}

			let createdVendorCodes = [],
				remainingSuggestedDocs = [];
			prodDocuments.forEach(prodDoc => {
				createdVendorCodes.push(prodDoc.vendor_code);
			});

			suggestedDocs.forEach((suggDoc) => {
				if (createdVendorCodes.indexOf(suggDoc.vendor_code) === -1) {
					remainingSuggestedDocs.push(suggDoc);
				}
			});

			dispatch(actions.setState({ suggestedDocs: remainingSuggestedDocs }));
			dispatch(actions.setState({ showGenerate: true }));

		} catch(err) {
			console.error(err);
		}

		dispatch(actions.setState({ saving: false }));
	},


	document_line_update: (documentIndex, dataSource, lineIndex, data) => async (dispatch, getState) => {
		const state     = getState(),
			  documents = dataSource === 'sap' ? 'documents' : 'suggestedDocs',
			  document  = state.purchasing[documents][documentIndex],
			  lines     = document.lines,
			  newLines  = [
					...lines.slice(0, lineIndex),
					{
						...lines[lineIndex],
						...data,
					},
					...lines.slice(lineIndex + 1),
			  ];


		dispatch(actions.set_document_line_data(documentIndex, documents, lineIndex, {
			lines: newLines,
		}));
	},


	load_pdf_document: (orderID) => async () => {
		const response = await api.get('/orders/order-document', {
			params: {
				order_id: orderID,
				type: 'purchase',
			},
		});

		let fileData = response.document.FileData;
		fileData = base64toPDF(fileData);

		const fileURL = URL.createObjectURL(fileData);

		if (fileData) {
			window.open(fileURL);
		} else {
			message.error(`Could not load report for document ${orderID}`);
		}


	},
};
