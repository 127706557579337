import api from 'api';
import makeSorter from 'lib/makeSorter';
import { regexpSearch } from 'lib/searchFns';
import ___ from '../constants';
import { result } from 'lodash';

const dateColumns = [
    'est_arrival_date',
]

const initialState = {
    currentEditRow: null,
    currentEditHeader: null,
    originalEditRow: null,
    detailLines: null,
    outgoingOrders: [],
    loading: false,
    currentDataSource: [],
    //modalType: null,
    columns: [],
    filteredSource: [],
    searchedSource: [],
    sortOrder: false,
    sortField: false,
    sorting: [],
    filters: [],
    requestedPage: 0,
    forceReload: false,
    //warehousePerson: '',
    logged_in_rep: '',
    filterValues: {
        order_date_formatted: null,
        event_date: null,
        status: null,
    },
    modalOpen: false,
    pictureAttachments: null,
    picturesLoading: false,
    msgAttachments: [],
    _msgObjects: [],
    attachmentErrors:[],


};

export const types = {
    SET_STATE: 'OUTGOING/SET_STATE',
    SET_FILTER_VALUES: 'OUTGOING/SETFILTERVALUES',
    START_LOADING: 'OUTGOING/START_LOADING',
    CHANGE_FILTERS: 'OUTGOING/CHANGE_FILTERS',
};

export const reducers = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_STATE:
            return { ...state, ...action.data };

        case types.SET_FILTER_VALUES:
            return {
                ...state,
                filterValues: {
                    ...state.filterValues,
                    ...action.data,
                },
            };

        case types.START_LOADING:
            return {
                ...state,
                requestedPage: action.data.requestedPage,
                take: action.data.take,
            };

        case types.CHANGE_FILTERS:
            return {
                ...state,
                forceReload: true,
                requestedSkip: 0,
                allOrders: [],
                filters: action.data.filters,
            };


        default:
            return state;
    }
};




export const actions = {
    setState: (data) => ({ type: types.SET_STATE, data }),

    setFilterValues: (data) => ({ type: types.SET_FILTER_VALUES, data }),

    startLoading: (data) => ({ type: types.START_LOADING, data }),

    setCurrentEditRow: (row) => async (dispatch, getState) => {
        console.log('[Outgoing.redux.js] setCurrentEditRow');
        console.log(row);
        dispatch({
            type: types.SET_STATE,
            data: {
                currentEditRow: row,
            },
        });
    },

    getShippingLocations: () => async (dispatch) => {
        console.log('[Outgoing.redux] getShippingLocations');
        dispatch(actions.setState({ loading: true }));

        try {
            let [shippingMethods] = await Promise.all([
                api.get('/sap/shipping-methods'),
            ]);

            //console.log('shippingMethods');
            //console.log(shippingMethods);
            dispatch(actions.setState({
                loading: false,
                shippingMethods: shippingMethods.shippingMethods,
            }));
        } catch (err) {
            console.error(err);
        }
    },

    getOutgoingOrders: () => async (dispatch, getState) => {
        console.log('[Outgoing.redux.js] getOutgoingOrders()');
        dispatch(actions.setState({ loading: true }));

        try {
            let result = await api.get('/warehouse/shipping');
            console.log(result);
            //console.log(result.outgoingOrders.recordset)
            let _result = result.outgoingOrders.recordset;
            //console.log(typeof _result)
            // console.log(typeof _result[0])

            const _arrayResult = [];
            for (let [key, value] of Object.entries(_result)) {
                //console.log(key, value);
                _arrayResult.push(value);
            }
            //console.log(_arrayResult)
            //console.log(typeof _arrayResult)

            dispatch(actions.setState({
                // outgoingOrders: [..._obj],
                // currentDataSource: [..._obj],
                // filteredSource: [..._obj],
                outgoingOrders: _arrayResult,
                currentDataSource: _arrayResult,
                filteredSource: _arrayResult,
            }));
        } catch (err) {
            console.error(err);
        }
        dispatch(actions.setState({ loading: false }));
    },

    searchCurrent: (searchValue) => (dispatch, getState) => {
        console.log('[Outgoing.redux] searchCurrent');

        let { filteredSource, sortOrder, sortField } = getState().warehouseOut;

        //console.log(filteredSource)
        //console.log(typeof filteredSource[0])
        let searchedSource = [];

        if (searchValue === '') {
            searchedSource = filteredSource;
        } else {
            for (let bp of filteredSource) {
                for (let prop in bp) {
                    // TODO_SEARCH does not work with dates or status
                    if (regexpSearch(searchValue, bp[prop])) {
                        searchedSource.push(bp);
                        break;
                    }
                }
            }
        }

        dispatch(actions.setState({
            searchedSource: [...searchedSource],
            searchValue,
            hasFilterBeenApplied: true,
            hasOrder: true,
        }));

        dispatch(actions.sortCurrent({
            field: sortField,
            order: sortOrder,
        }));
    },

    sortCurrent: ({ field, order }) => (dispatch, getState) => {
        //console.log('[Outgoing.redux] sortCurrent ')
        //console.log(field)
        //console.log(order)
        let { searchedSource, columns } = getState().warehouseOut;

        const numberSortColumns = [
            ...dateColumns,
            'card_code',
        ];

        let newColumns = columns.map((col) => {
            if (col.dataIndex === field) {
                return { ...col, sortOrder: order };
            } else {
                return { ...col, sortOrder: false };
            }
        });

        let outgoingOrders = searchedSource; // IMPORTANT

        if (field !== false) {
            let type = 'string';
            if (numberSortColumns.indexOf(field) !== -1) {
                type = 'number';
            }
            outgoingOrders = searchedSource.sort(makeSorter(type, field));
        }

        if (order === 'ascend') {
            outgoingOrders = outgoingOrders.reverse();
        }

        dispatch(actions.setState({
            columns: newColumns,
            outgoingOrders: [...outgoingOrders],
            sortOrder: order,
            sortField: field
        }));
    },

    getOutgoing: () => async (dispatch, getState) => {
        console.log('[Outgoing.redux.js] getOutgoing()');
        dispatch(actions.setState({ loading: true }));

        try {
            let result = await api.get('/warehouse/shipping');
            //console.log(result)
            //console.log(result.outgoingOrders.recordset);
            let _result = result.outgoingOrders.recordset;
            //console.log(typeof _result)
            // console.log(typeof _result[0])

            const _arrayResult = [];
            for (let [key, value] of Object.entries(_result)) {
                //console.log(key, value);
                _arrayResult.push(value);
            }
            //console.log(_arrayResult)
            //console.log(typeof _arrayResult)

            dispatch(actions.setState({
                // outgoingOrders: [..._obj],
                // currentDataSource: [..._obj],
                // filteredSource: [..._obj],
                outgoingOrders: _arrayResult,
                currentDataSource: _arrayResult,
                filteredSource: _arrayResult,
            }));
        } catch (err) {
            console.error(err);
        }
        dispatch(actions.setState({ loading: false }));
    },

    getOutgoingDetails: (record) => async (dispatch, getState) => {
        console.log('[Outgoing.redux.js] getOutgoingDetails()');
        dispatch(actions.setState({ loading: true }));
        //console.log(state.currentEditRow);
        //console.log(record);
        //console.log(state.currentEditRow.docEntry)
        //console.log(typeof state.currentEditRow.docEntry)

        try {
            // QUERY Warehouse.GetOutgoingLines
            // let result = await api.get(`/warehouse/shipping/details/${state.currentEditRow.docEntry}`, {
            //     params: {
            //         docEntry: state.currentEditRow.docEntry,
            //     },
            // });
            let result = await api.get(`/warehouse/shipping/details/${record.docEntry}`, {
                params: {
                    docEntry: record.docEntry,
                },
            });

            //console.log(result)
            //console.log(result.detailLines.recordset);
            dispatch(actions.setState({ detailLines: result.detailLines.recordset }));
        } catch (err) {
            console.error(err);
        }
        dispatch(actions.setState({ loading: false }));
    },

    writeDelivery: (currentEditRow, editDetailsLines, popSuccessCB, popWarnEmpty) => async (dispatch, getState) => {
        console.log('[Outgoing.redux.js] writeDelivery()');
        console.log(currentEditRow);
        console.log(editDetailsLines);

        dispatch(actions.setState({ loading: true }));

        let _filtered = editDetailsLines.filter((r) => (r.qtyToDeliver && r.qtyToDeliver > 0));
        //console.log(_filtered);

        let sendBody = {
            BaseDocEntry: currentEditRow.docEntry,
            DocNum: currentEditRow.docNum,
            BaseDocType: 22, // TODO OR currentEditRow.objType
            CardCode: currentEditRow.cardCode,
            //deliveryShipType: currentEditRow.deliveryShipType,
            //deliveryShipMethod: currentEditRow.deliveryShipMethod,
            trackingNum: currentEditRow.trackingNum, // NOTE original trackNo
        };

        if (_filtered && _filtered.length > 0) {
            sendBody.submitRows = _filtered;
        }

        // TODO_BLANK_NOTES need to prevent writing comments if they are blank, except when they would be intentionally blank
        if (currentEditRow.notes && currentEditRow.notes !== '') {
            sendBody.Comments = currentEditRow.notes;
        }

        // only send deliveryShipMethod and deliveryShipType if they exist
        if (currentEditRow.deliveryShipMethod !== -1 && currentEditRow.deliveryShipType !== -1) {
            sendBody.deliveryShipType = currentEditRow.deliveryShipType;
            sendBody.deliveryShipMethod = currentEditRow.deliveryShipMethod;
        }

        // only send deliveryTrackNum if it exists and is not blank
        if (currentEditRow.deliveryTrackNum && currentEditRow.deliveryTrackNum !== '') {
            sendBody.deliveryTrackNum = currentEditRow.deliveryTrackNum;
        }

        let _filteredECOM = editDetailsLines.filter((r) => (r.qtyToECOM && r.qtyToECOM > 0));
        //console.log(_filteredECOM);
        if (_filteredECOM && _filteredECOM.length > 0) {
            sendBody.ECOMrows = _filteredECOM;
        }

        //console.log(sendBody);

        if ((!sendBody.submitRows || sendBody.submitRows.length <= 0) && (!sendBody.ECOMrows || sendBody.ECOMrows <= 0)) {
            console.log('nothing is marked for shipping or ECOM');
            popWarnEmpty();
            dispatch(actions.setState({ loading: false }));
        } else {
            try {
                let result = await api.put('/warehouse/write-delivery',
                    sendBody);
                console.log(result);
                console.log(result.data);

                //console.log(result.success);
                if (result.success) {
                    console.log('success');
                    popSuccessCB();
                    dispatch(actions.getOutgoingOrders());
                }

                //console.log(result.errorMsg); // errorMsg: "One of the base documents has already been closed"

            } catch (err) {

                // message: 'One of the base documents has already been closed '

                console.error((result & result.errorMsg) ? result.errorMsg : 'Could not Write');
                console.error(err);
            }
            dispatch(actions.setState({ loading: false }));

        }
    },
    updateQCNotes: (currentEditRow, popSuccessCB, popFailCB) => async (dispatch, getState) => {
        console.log('[Outgoing.redux.js] updateQCNotes()');
        console.log(currentEditRow);

        dispatch(actions.setState({ loading: true }));

        let sendBody = {
            BaseDocEntry: currentEditRow.docEntry,
            BaseDocType: 22, // TODO OR currentEditRow.objType
            CardCode: currentEditRow.cardCode,
        };

        if (currentEditRow.qcNotes && currentEditRow.qcNotes !== '') {
            sendBody.qcNotes = currentEditRow.qcNotes;
        } else {
            popFailCB();
        }

        //console.log(sendBody);

        try {
            let result = await api.put('/warehouse/write-qc-notes',
                sendBody);
            //console.log(result);
            //console.log(result.data);
            //console.log(result.success);
            if (result.success) {
                console.log('updateQCNotes success');
                setTimeout(() => {
                    popSuccessCB();
                    dispatch(actions.getOutgoingOrders());
                }
                    , 800);
            }

        } catch (err) {
            popFailCB();
            console.error((result & result.errorMsg) ? result.errorMsg : 'Could not Write');
            console.error(err);
        }
        dispatch(actions.setState({ loading: false }));
    },
    updateQCStatus: (currentEditRow, popSuccessCB, popFailCB) => async (dispatch, getState) => {
        console.log('[Outgoing.redux.js] updateQCStatus()');
        //console.log(currentEditRow);
        console.log(currentEditRow.qcStatus);
        dispatch(actions.setState({ loading: true }));

        let sendBody = {
            BaseDocEntry: currentEditRow.docEntry,
            BaseDocType: 22, // TODO OR currentEditRow.objType
            CardCode: currentEditRow.cardCode,
        };

        if (currentEditRow.qcStatus && currentEditRow.qcStatus !== '') {
            sendBody.qcStatus = currentEditRow.qcStatus;
        }

        //console.log(sendBody);

        try {
            let result = await api.put('/warehouse/write-qc-status',
                sendBody);
            //console.log(result);
            //console.log(result.data);
            //console.log(result.success);
            if (result.success) {
                console.log('updateQCStatus success');
                setTimeout(() => {
                    popSuccessCB();
                    dispatch(actions.getOutgoingOrders());
                }
                    , 800);
            }

        } catch (err) {
            popFailCB();
            console.error((result & result.errorMsg) ? result.errorMsg : 'Could not Write');
            console.error(err);
        }
        dispatch(actions.setState({ loading: false }));
    },

    getAttachments: (atc) => async (dispatch, getState) => {
        console.log('getAttachments');
        // console.log(atc);
        // console.log(atc.allAtcEntry);
        // console.log(atc.filteredAtcEntry);

        dispatch(actions.setState({
            loading: true,
            picturesLoading: true,
        }));

        let atc_entry = atc.filteredAtcEntry;

        // QUERY Orders.GetAttachments
        let atcData = await api.get(`/warehouse/artwork/get-attachments/${atc_entry}`, {
            params: {
                atcEntry: atc_entry,
            },
        });

        //console.log(atcData);
        console.log(atcData.attachments);
        console.log(atcData.errors);

        // console.log(atcData.data)
        // console.log(atcData.data.attachments)
        // console.log(atcData.data.msgAttachments)
        // console.log(atcData.data.msgObjects)
        dispatch(actions.setState({
            loading: false,
            picturesLoading: false,
            modalOpen: true,
            pictureAttachments: atcData.attachments,
            msgAttachments: atcData.msgAttachments, 	// base64
            msgObjects: atcData.msgObjects, 			// text information in objects
            attachmentErrors: atcData.errors
        }));
    },

};
