import api from 'api';

let typesRaw = {
	SET_STATE: 'SET_STATE',
	SET_PROPERTIES: 'SET_PROPERTIES',
	SET_PROPERTY_VALUES: 'SET_PROPERTY_VALUES',
	SET_PROPERTY_OPTION_SET_SELECTION: 'SET_PROPERTY_OPTION_SET_SELECTION',
	RESET_PROPERTY_FORM: 'RESET_PROPERTY_FORM',
	SET_OPTION_SETS: 'SET_OPTION_SETS',
	SET_OPTION_SET_VALUES: 'SET_OPTION_SET_VALUES',
	OPTION_SET_ADD_VALUE: 'OPTION_SET_ADD_VALUE',
	SET_OPTION_SET_VALUE: 'SET_OPTION_SET_VALUE',
	RESET_OPTION_SET_FORM: 'RESET_OPTION_SET_FORM',
	OPTION_SET_VALUE_REMOVE: 'OPTION_SET_VALUE_REMOVE',
	RESET_ALL: 'RESET_ALL',
};

export const types = Object.keys(typesRaw).reduce((all, key) => ({
	...all,
	[ key ]: `PRODUCT_CONFIGURATOR/${typesRaw[ key ]}`,
}), {});


const defaults = {
	option_set: {
		optset_id: null,
		optset_label: '',
		active: true,
		values: [
			{
				osval_id: null,
				optset_id: null,
				osval_label: '',
				osval_value: '',
				key: 0,
			},
		],
	},

	option_set_value: {
		osval_id: null,
		optset_id: null,
		osval_label: '',
		osval_value: '',
		key: 0,
	},

	property: {
		property_id: null,
		property_label: '',
		property_use_options: true,
		property_required: false,
		active: true,
		option_sets: [],
	},
	property_option_set_selection: [],

	model_definition: {
		modeldef_id: null,
		modeldef_label: '',
		modeldef_code: '',
		modeldef_code_type: '',
		modeldef_sku_format: '',
		modeldef_foreign_format: '',
		modeldef_next_sku: '',
		modeldef_padding_length: 0,
		modeldef_padding_pattern: '0',
		modeldef_attachment_path: '',
		modeldef_notes: '',
	},

	model_definition_property: {
		modeldefprop_id: null,
		modeldef_id: null,
		property_id: null,
		optset_id: null,
		modeldefprop_use_in_sku: false,
		modeldefprop_sku_group: null,
		modeldefprop_padding_length: 3,
		modeldefprop_padding_pattern: '0',
		modeldefprop_notes: '',
	},
};

const initialState = {
	loading: false,



	option_set: { ...defaults.option_set },
	option_sets: [],
	option_sets_total_rows: 0,
	option_sets_page_number: 0,
	option_sets_page_size: 10,
	option_sets_search_string: '',



	option_set_value: { ...defaults.option_set_value },
	option_set_values: [],
	option_set_values_total_rows: 0,
	option_set_values_page_number: 0,
	option_set_values_page_size: 10,



	property: { ...defaults.property },
	property_step: 0,
	properties: [],
	properties_total_rows: 0,
	properties_page_number: 0,
	properties_page_size: 10,
	properties_search_string: '',



	model_definition: { ...defaults.model_definition },
	model_definitions: [],
	model_definitions_total_rows: 0,



	model_definition_property: { ...defaults.model_definition_property },
	model_definition_properties: [],
	model_definition_properties_total_rows: 0,
};




export const reducers = (state = initialState, action) => {
	switch(action.type) {
		case types.SET_STATE:
			return { ...state, ...action.data };


		case types.SET_PROPERTIES:
			return {
				...state,
				properties: action.properties,
				properties_total_rows: action.total_rows,
			};


		case types.SET_PROPERTY_VALUES:
			return {
				...state,
				property: {
					...state.property,
					...action.data,
				},
			};


		case types.RESET_PROPERTY_FORM:
			return {
				...state,
				property: { ...defaults.property },
				property_step: 0,
				property_option_set_selection: [],
			};


		case types.SET_PROPERTY_OPTION_SET_SELECTION:
			return {
				...state,
				property_option_set_selection: [ ...action.selected ],
				property: {
					...state.property,
					option_sets: action.selected.map(rowID => state.option_sets[ rowID ].optset_id),
				},
			};


		case types.SET_OPTION_SETS:
			return {
				...state,
				option_sets: action.option_sets,
				option_sets_total_rows: action.total_rows,
			};

		case types.SET_OPTION_SET_VALUES:
			return {
				...state,
				option_set: {
					...state.option_set,
					...action.data,
				},
			};


		case types.RESET_OPTION_SET_FORM:
			return {
				...state,
				option_set: { ...defaults.option_set },
			};


		case types.OPTION_SET_ADD_VALUE:
			return {
				...state,
				option_set: {
					...state.option_set,
					values: [
						...state.option_set.values,
						{
							...defaults.option_set_value,
							key: state.option_set.values.length,
						},
					],
				},
			};


		case types.SET_OPTION_SET_VALUE:
			return {
				...state,
				option_set: {
					...state.option_set,
					values: [
						...state.option_set.values.slice(0, action.index),
						{
							...state.option_set.values[ action.index ],
							...action.data,
						},
						...state.option_set.values.slice(action.index + 1),
					],
				},
			};


		case types.OPTION_SET_VALUE_REMOVE:
			return {
				...state,
				option_set: {
					...state.option_set,
					values: [
						...state.option_set.values.slice(0, action.index),
						...state.option_set.values.slice(action.index + 1),
					].map((row, index) => ({ ...row, key: index })),
				},
			};


		case types.RESET_ALL:
			return { ...initialState };


		default:
			return state;
	}
};

export default reducers;



export const actions = {
	setState: (data) => ({ type: types.SET_STATE, data }),


	reset_all: () => ({ type: types.RESET_ALL }),


	setPropertyValues: (data) => ({ type: types.SET_PROPERTY_VALUES, data }),


	properties_get: (params = {}) => async (dispatch, getState) => {
		const state  = getState().configurator,
			  page   = state.properties_page_number,
			  limit  = state.properties_page_size,
			  search = state.properties_search_string;

		dispatch(actions.setState({ loading: true }));

		try {
			let results = await api.get('/configurator/properties', {
				params: {
					page: page,
					limit: limit,
					search: search,
					...params,
				},
			});

			dispatch({ type: types.SET_PROPERTIES, properties: results.properties, total_rows: results.total_rows });
		} catch(err) {
			// do nothing
		}

		dispatch(actions.setState({ loading: false }));
	},


	properties_set_search_string: (searchString) => (dispatch) => {
		dispatch(actions.setState({ properties_search_string: searchString }));
		//dispatch(actions.properties_get());
	},


	property_set_option_set_selection: (selected) => ({ type: types.SET_PROPERTY_OPTION_SET_SELECTION, selected }),


	property_load_for_edit: (property_id) => async (dispatch, getState) => {
		dispatch(actions.setState({ loading: true }));

		// Get the property
		try {
			let results = await api.get('/configurator/properties/edit', {
				params: {
					property_id: property_id,
				},
			});

			dispatch(actions.setState({
				option_sets: results.option_sets,
				option_sets_total_rows: results.option_sets_total_rows,
				property: { ...results.property },
				property_option_set_selection: results.property_option_set_selection,
				property_step: 0,
			}));

		} catch(err) {
			// do nothing
		}

		dispatch(actions.setState({ loading: false }));
	},


	property_save: (saveAsProp) => async (dispatch, getState) => {
		let property = getState().configurator.property;
		dispatch(actions.setState({ loading: true }));

		try {
			await api.put('/configurator/properties/save', {
				property: property,
			});
			
			if (saveAsProp) {
				dispatch(actions.option_set_reset());
				dispatch(actions.option_sets_get({}, true));
			} else {
				dispatch(actions.property_reset());
				dispatch(actions.properties_get());
			}
		} catch(err) {
			// do nothing
		}

		dispatch(actions.setState({ loading: false }));
	},


	property_reset: () => ({ type: types.RESET_PROPERTY_FORM }),


	setOptionSetValues: (data) => ({ type: types.SET_OPTION_SET_VALUES, data }),


	option_set_reset: () => ({ type: types.RESET_OPTION_SET_FORM }),


	option_set_add_value: () => ({ type: types.OPTION_SET_ADD_VALUE }),


	set_option_set_value: (index, data) => ({ type: types.SET_OPTION_SET_VALUE, index, data }),


	option_set_value_remove: (index) => ({ type: types.OPTION_SET_VALUE_REMOVE, index }),


	option_sets_get: (force = false) => async (dispatch, getState) => {
		const state       = getState().configurator,
			  option_sets = state.option_sets;

		if(option_sets.length && !force) {
			return true;
		}

		dispatch(actions.setState({ loading: true }));

		try {
			let results = await api.get('/configurator/option-sets');

			dispatch({
				type: types.SET_OPTION_SETS,
				option_sets: results.option_sets,
			});
		} catch(err) {
			// do nothing
		}

		dispatch(actions.setState({ loading: false }));
	},


	option_sets_set_search_string: (searchString) => (dispatch) => {
		dispatch(actions.setState({ option_sets_search_string: searchString }));
		dispatch(actions.option_sets_get());
	},


	option_set_load_for_edit: (optset_id) => async (dispatch, getState) => {
		dispatch(actions.setState({ loading: true }));

		// Get the property
		try {
			let results = await api.get('/configurator/option-sets/edit', {
				params: {
					optset_id: optset_id,
				},
			});

			dispatch(actions.setState({
				option_set: { ...results.option_set },
			}));

		} catch(err) {
			// do nothing
		}

		dispatch(actions.setState({ loading: false }));
	},


	option_set_save: (saveAsProp) => async (dispatch, getState) => {
		let option_set = getState().configurator.option_set;
		dispatch(actions.setState({ loading: true }));

		try {
			const result = await api.put('/configurator/option-sets/save', {
				option_set: option_set,
			});
			if (saveAsProp) {
				dispatch(actions.setState({
					property: {
						property_label: option_set.optset_label,
						option_sets: [ result.optset_id ]
					}
				}));
				
				dispatch(actions.property_save(saveAsProp));
			} else {
				dispatch(actions.option_set_reset());
				dispatch(actions.option_sets_get({}, true));
				dispatch(actions.setState({ loading: false }));
			}

		} catch(err) {
			// do nothing
			dispatch(actions.setState({ loading: false }));
		}

	},

};
